import * as React from "react"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import Cake from "../components/cake";
import BrandHeading from "../components/brand-heading";
import { Link } from "gatsby"
import { useProducts } from "../hooks/use-products";
import { usePrices } from "../hooks/use-prices";

export default function Cakes() {
  const prices = usePrices();
  const products = useProducts();
  
  const cakes = products.map(cake=>{
    const cakePrices = prices.filter(x => x.product.id === cake.id);
    cake = {
      ...cake
      , code: cake.metadata.code
      , imageFile: cake.localImage[0]
      , options: cakePrices.map(x => { return { id: x.id, size: x.description, price: (x.unit_amount) / 100 }; })
    }
    return <Link key={cake.code} to={`/cakes/${cake.code}`}>
      <Cake cake={cake} />
    </Link>
  })
  
  return (
    <>
      <Seo title="Cakes" />
      <BrandHeading title="cakes" />
      <section className="flex flex-wrap justify-evenly">
        {cakes}
      </section>
    </>
  )
}

