import React, { useState } from "react"
import Seo from "../components/seo"
import BrandHeading from "../components/brand-heading";
import { FormCheckbox, FormTextArea, FormButton } from '../components/form-elements';
import { navigate } from "gatsby";
import CartSummary from "../components/cart-summary";
import { useCheckoutActionContext, useCheckoutContext } from "../components/checkout-context";

export default function CheckoutNotes() {
    const { upsertOrderNotes } = useCheckoutActionContext();
    const { orderNotes } = useCheckoutContext();

    const [note, setNote] = useState(() => orderNotes.note ? orderNotes.note : "");
    const [isSurprise, setIsSurprise] = useState(() => orderNotes.isSurprise ? orderNotes.isSurprise : "");

    const form = {
        note, setNote,
        isSurprise, setIsSurprise,
    }

    const formSubmit = (e) => {
        e.preventDefault();
        upsertOrderNotes({ note, isSurprise })
        navigate('/checkout-review/');
    };

    return (
        <>
            <Seo title="Checkout Notes" />
            <BrandHeading title="Checkout" />
            <section>
                <CartSummary />
                <Form form={form} onSubmit={formSubmit} />
            </section>
        </>
    )
}

function Form({ form, onSubmit }) {
    return (
        <div className="grid grid-cols-1 gap-6 max-w-2xl mx-auto mt-8">
            <div className="text-center">
                <h1 className="font-bold text-lg mb-3">NOTES</h1>
                <h3 className="font-semibold mb-1">Order Notes</h3>
                <p className="leading-4 font-sans-serif">Please tell us if you have any dietary requirements, specific delivery instructions or other special requests!</p>
            </div>
            <FormTextArea label={""} value={form.note} onChange={form.setNote} />
            <div className="text-center">
                <p className="font-semibold mb-1">Is this order a surprise? Please tick if applicable</p>
                <p className="leading-4 font-sans-serif">Make sure your details in the billing address area are correct should we need to contact you.</p>
            </div>
            <FormCheckbox label={"This is for a surprise, please do not contact the recipient."} value={form.isSurprise} onChange={form.setIsSurprise} />
            <hr />
            <div className="max-w-xs w-full mx-auto my-8">
                <FormButton
                    className={"w-full mb-4"}
                    label={"REVIEW"}
                    onClick={e => { onSubmit(e) }} />
                <FormButton className="w-full mb-4" label={"BACK"} onClick={() => navigate(`/checkout-fulfillment/`)} isSecondary={true} />
            </div>
        </div>
    )
}
