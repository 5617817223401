import React from "react"

import CartProvider from "./src/components/cart-context"
import CheckoutProvider from "./src/components/checkout-context"
import Layout from "./src/components/layout";

export const wrapRootElement = ({ element }) => (
    <CartProvider>
        <CheckoutProvider>
            <Layout>
                {element}
            </Layout>
        </CheckoutProvider>
    </CartProvider>
)