import React, { useState } from "react"
import Seo from "../components/seo"
import BrandHeading from "../components/brand-heading";
import {
    FormInputDateTime
    , FormRadioGroup
    , FormInputAddressAutoComplete, FormButton
} from '../components/form-elements';
import { dateValidation, requiredValidation } from "../helpers/form-validation-helper";
import { navigate } from "gatsby";
import { useCheckoutActionContext, useCheckoutContext } from "../components/checkout-context";
import useUpdateEffect from "../hooks/use-update-effect";
import CartSummary from "../components/cart-summary";
import usePickupAddress from "../hooks/use-pickup-address";
import { FULFILLMENT } from "../constants";
import { getShippingRate } from "../helpers/shipping-rate-helper";

export default function CheckoutFulfillment() {
    const { upsertFulfillment } = useCheckoutActionContext();
    const { fulfillment } = useCheckoutContext();
    const [collectionDate, setCollectionDate] = useState(() => fulfillment.collectionDate ? fulfillment.collectionDate : "");
    const collectionDateValidations = [(x) => requiredValidation(x, "Date"), dateValidation];

    const [fulfillmentType, setfulfillmentType] = useState(() => fulfillment.fulfillmentType ?? FULFILLMENT.PICKUP);
    const [address, setAddress] = useState(() => fulfillment.address ? fulfillment.address : "");
    const addressValidations = [(x) => requiredValidation(x, "Address")];
    const [isFormSubmitting, setIsFormSubmitting] = useState(() => false);
    const shouldFormButtonDisable = () =>
        !!((collectionDateValidations.find(f => f(collectionDate)))
            || (fulfillmentType != FULFILLMENT.PICKUP && addressValidations.find(f => f(address))));

    const [formSubmitDisabled, setFormSubmitDisabled] = useState(() => shouldFormButtonDisable());

    const form = {
        collectionDate, setCollectionDate, collectionDateValidations,
        fulfillmentType, setfulfillmentType,
        address, setAddress, addressValidations,
        formSubmitDisabled, isFormSubmitting
    }

    const formSubmit = (e) => {
        e.preventDefault();
        const upsertFulfillmentWithShipping = async () => {
            setIsFormSubmitting(true);
            const shippingRate = (fulfillmentType === FULFILLMENT.DELIVERY)
                ? await getShippingRate(address) : {};
            upsertFulfillment({ collectionDate, fulfillmentType, address, shippingRate })
        }
        upsertFulfillmentWithShipping()
            .then(() => navigate('/checkout-notes/'))
            .catch(() => { setIsFormSubmitting(true); console.error("Unable to dispatch fulfillment."); });
    };

    useUpdateEffect(
        () => {
            setFormSubmitDisabled(shouldFormButtonDisable());
        },
        [collectionDate, address, fulfillmentType]
    );

    return (
        <>
            <Seo title="Checkout Fulfillment" />
            <BrandHeading title="Checkout" />
            <section>
                <CartSummary />
                <Form form={form} onSubmit={formSubmit} />
            </section>
        </>
    )
}

function Form({ form, onSubmit }) {

    return (
        <div className="grid grid-cols-1 gap-6 max-w-2xl mx-auto mt-8">
            <FormRadioGroup
                label={""}
                value={form.fulfillmentType}
                onChange={form.setfulfillmentType}
                groupName={"fulfillment"}
                inline={true}
                options={[
                    { value: FULFILLMENT.PICKUP, labels: ["PICK-UP"] },
                    { value: FULFILLMENT.DELIVERY, labels: ["DELIVERY"] },
                ]}
            />
            <FormInputDateTime label={form.fulfillmentType == FULFILLMENT.PICKUP ? "COLLECTION DATE *" : "DELIVERY DATE *"} placeholder={"dd / mm / yyyy"}
                value={form.collectionDate}
                onChange={form.setCollectionDate}
                validations={form.collectionDateValidations}
            />
            {
                (form.fulfillmentType == FULFILLMENT.PICKUP) ?
                    <label className="block">
                        <span>{"COLLECTION ADDRESS"}</span>
                        <p>{usePickupAddress()}</p>
                    </label>
                    :
                    <FormInputAddressAutoComplete
                        label={"DELIVERY ADDRESS *"}
                        value={form.address}
                        onChange={form.setAddress}
                        placeholder={"Please enter your full address here"}
                        validations={form.addressValidations} />

            }
            <hr />
            <div className="w-full max-w-xs mx-auto my-8">
                <FormButton
                    disabled={form.formSubmitDisabled || form.isFormSubmitting}
                    className={"w-full mb-4"}
                    label={"CONTINUE"}
                    onClick={e => { onSubmit(e) }}
                    isProcessing={form.isFormSubmitting} />
                <FormButton className="w-full mb-4" label={"BACK"} onClick={() => navigate(`/checkout-customer/`)} isSecondary={true} />
                <p className="italic text-center text-pas-orange text-sm">
                    {form.formSubmitDisabled ? `--- Please fill-out all required (*) fields to enable the CONTINUE button ---` : ""}
                </p>
            </div>
        </div>
    )
}