export const STORE_KEY = {
    CART_ITEMS: "pasteleria.cartItems",
    CHECKOUT: "pasteleria.checkout"
}

export const CART_ACTION = {
    ADD: 'cart-add',
    UPDATE: 'cart-update',
    REMOVE: 'cart-remove',
    CLEAR: 'cart-clear'    
}

export const CHECKOUT_ACTION = {
    UPSERT_CUSTOMER_INFO: 'checkout-upsert-customer-info',
    UPSERT_NOTES: 'checkout-upsert-notes',
    UPSERT_FULFILLMENT: 'checkout-upsert-fulfillment',
    CLEAR: 'checkout-clear'   
}

export const FULFILLMENT = {
    DELIVERY: 'delivery',
    PICKUP: 'pickUp'
}
