import * as React from "react"
import Seo from "../components/seo"
import BrandHeading from "../components/brand-heading";

function SectionDivider({ name }) {
    return (
        <div className="
        bg-pas-peach text-white
        inline-block  align-middle
        w-full p-5 pt-6 mb-8
        text-xl text-center font-medium
        rounded-sm ">
            {name}
        </div>
    )
}

function Contact() {
    return (
        <>
            <Seo title="Contact" />
            <BrandHeading title="contact us" />
            <section className="flex flex-wrap justify-evenly">
                <SectionDivider name={"WE'RE HERE TO HELP !"} />
                <div className="max-w-3xl">
                    <p className="block w-full text-center mb-6 font-sans-serif text-lg font-light">For any order enquiries or special requests, reach out to us anytime and we will happily assist you as best we can!</p>
                    <h3 className="block w-full text-center">EMAIL</h3>
                    <p className="block w-full text-center mb-6 font-sans-serif text-lg font-light">hello@pastelería.co.nz</p>
                    <h3 className="block w-full text-center">PHONE</h3>
                    <p className="block w-full text-center mb-10 font-sans-serif text-lg font-light">(021) 029 17082</p>
                    <h3 className="block w-full text-center mb-4">OR</h3>
                    <div className="w-full flex flex-row">
                        <hr className="flex-auto mt-3  text-pas-peach border-pas-peach border-t-2" />
                        <h3 className="flex-none px-8 text-center mb-10 bg-white">CONNECT WITH US!</h3>
                        <hr className="flex-auto mt-3  text-pas-peach border-pas-peach border-t-2" />
                    </div>

                    <p className="block w-full text-center mb-6 font-sans-serif text-lg font-light">Follow us to be the first to see new product launches, special promotions or win surprise giveaways and discount vouchers!</p>
                    <h3 className="block w-full text-center">INSTAGRAM</h3>
                    <p className="block w-full text-center mb-6 font-sans-serif text-lg font-light">@pasteleria_nz</p>
                </div>
            </section>
        </>
    )
}

export default Contact
