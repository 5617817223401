import * as React from "react"
import Seo from "../components/seo"
import BrandHeading from "../components/brand-heading";
import { Link, navigate } from "gatsby"

export default function Contact() {
    return (
        <>
            <Seo title="Terms & Conditions" />
            <BrandHeading title="terms & conditions" />
            <section className="flex flex-wrap justify-evenly">
                <div className="text-justify font-sans-serif font-light max-w-3xl mx-auto">

                    <ol className="list-decimal list-outside text-2xl font-semibold">
                        <li className="mb-8">
                            <h3 className="text-2xl font-semibold mb-4">GENERAL</h3>
                            <ol className="list-[lower-alpha] list-outside ml-6 text-base font-light">
                                <li className="mb-4">This agreement applies to all purchases of Pasteleria products and services by you from the Pasteleria website and replaces all previous agreements between you and us.</li>
                                <li className="mb-4">Pasteleria may change the terms of this agreement from time to time using the process set out in clause 23.</li>
                                <li className="mb-4">This is an important agreement which you must read through before purchasing products from our websites. Visiting and/or making a purchase from our website specifies you have agreed to these terms and conditions ("Terms of Service", "Terms"), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms & Conditions apply to all users of the site, including without limitation, users who are browsers, vendors, customers, merchants, and/or contributors of content.</li>
                            </ol>
                        </li>
                        <li className="mb-8">
                            <h3 className="text-2xl font-semibold mb-4">DEFINITIONS</h3>
                            <ol className="list-[lower-alpha] list-outside ml-6 text-base font-light">
                                <li className="mb-4">
                                    In this agreement unless the context otherwise requires:
                                    <div className="ml-6 mt-4">
                                        <p className="mb-4">"You" or "your" means the purchaser.</p>
                                        <p className="mb-4">"Us", "we" or "our" means Pasteleria.</p>
                                        <p className="mb-4">"Agreement" means this agreement and includes any other terms incorporated into it by reference.</p>
                                        <p className="mb-4">"Information" means all the information supplied by you to us via the website or otherwise and includes, for example, your name, contact details, credit card details and your payment and transactions history.</p>
                                        <p className="mb-4">"GST" means goods and services tax.</p>
                                        <p className="mb-4">"Products" means all products available for purchase on our website.</p>
                                        <p className="mb-4">"Services" means all services available for purchase on our website.</p>
                                        <p className="mb-4">"Website" means our website, accessible via the <Link to='/' className="text-pas-peach-darkest font-bold">www.pasteleria.co.nz</Link>.</p>
                                    </div>
                                </li>
                            </ol>
                        </li>

                        <li className="mb-8">
                            <h3 className="text-2xl font-semibold mb-4">PURCHASE TERMS</h3>
                            <ol className="list-[lower-alpha] list-outside ml-6 text-base font-light">
                                <li className="mb-4">
                                    When purchasing products on the website you confirm and acknowledge that:
                                    <ol className="list-[lower-roman] list-outside ml-6 text-base font-light">
                                        <li className="my-4">You are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.</li>
                                        <li className="mb-4">You may not use our products for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).</li>
                                        <li className="mb-4">You must not transmit any worms or viruses or any code of a destructive nature.</li>
                                        <li className="mb-4">All information supplied by you to us is true and correct at the time you make any purchase on the website.</li>
                                        <li className="mb-4">Notwithstanding the above, we may refuse to accept your order at our sole discretion.</li>
                                        <li className="mb-4">We may disclose your information to your bank and Credit Card Company and for the purposes of any debt recovery proceedings we may issue against you.</li>
                                        <li className="mb-4">A breach or violation of any of these Terms will result in an immediate termination of your Services.</li>
                                    </ol>
                                </li>
                            </ol>
                        </li>
                    </ol>


                    <ol className="list-decimal list-outside text-2xl font-semibold">
                        <li className="mb-8">
                            <h3 className="text-2xl font-semibold mb-4"></h3>
                            <ol className="list-[lower-alpha] list-outside ml-6 text-base font-light">
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>

                            </ol>
                        </li>
                    </ol>

                    4. PRICE
                    4.1. Prices quoted on our website are inclusive of GST. You must pay us the full price quoted on the website at the time the order is submitted. Prices are given in New Zealand dollars.
                    4.2. We reserve the right to vary our prices without notice. No adjustments are made if the price of a product increases or decreases between the time the order is submitted and the time the product is delivered.

                    <ol className="list-decimal list-outside text-2xl font-semibold">
                        <li className="mb-8">
                            <h3 className="text-2xl font-semibold mb-4"></h3>
                            <ol className="list-[lower-alpha] list-outside ml-6 text-base font-light">
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>

                            </ol>
                        </li>
                    </ol>

                    5. PRODUCTS OR SERVICES AVAILABILITY
                    5.1. All products are sold subject to their availability. Promotional items may be limited as to quantity. We shall not be responsible for our inability to supply products or services to you where they are not presently available or where our stock of the relevant product(s) has been exhausted.
                    5.2. Certain products or services may be available exclusively online through the website. These products or services may have limited quantities and are subject to return or exchange only according to our Return Policy.
                    5.3. We reserve the right, but are not obligated, to limit the sales of our products or Services to any person, geographic region or jurisdiction. We may exercise this right on a case-by-case basis.
                    5.4. We reserve the right to limit the quantities of any products or services that we offer.
                    5.5. All descriptions of products or product pricing are subject to change at any time without notice, at the sole discretion of us. We reserve the right to discontinue any product at any time. Any offer for any product or service made on this site is void where prohibited.

                    <ol className="list-decimal list-outside text-2xl font-semibold">
                        <li className="mb-8">
                            <h3 className="text-2xl font-semibold mb-4"></h3>
                            <ol className="list-[lower-alpha] list-outside ml-6 text-base font-light">
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>

                            </ol>
                        </li>
                    </ol>

                    6. DELIVERY COST
                    6.1. Product prices quoted do not include delivery costs. Any delivery charges in relation to your order will be notified to you at the time you place your order and
                    be added to the price. Purchases made on our website will be delivered only within Auckland, New Zealand.

                    <ol className="list-decimal list-outside text-2xl font-semibold">
                        <li className="mb-8">
                            <h3 className="text-2xl font-semibold mb-4"></h3>
                            <ol className="list-[lower-alpha] list-outside ml-6 text-base font-light">
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>

                            </ol>
                        </li>
                    </ol>

                    7. ERRORS, INACCURACIES AND OMISSIONS
                    7.1. In the event that computer error results in a material misstatement of the price of your order we may, prior to your receipt of the order or at any time thereafter, require that you pay us the difference between the amount actually paid by you in respect of your order and the correct amount payable.
                    7.2. Occasionally there may be information on our site or in the Service that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers, product shipping charges, transit times and availability. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information in the Service or on any related website is inaccurate at any time without prior notice (including after you have submitted your order).
                    7.3. We undertake no obligation to update, amend or clarify information in the Service or on any related website, including without limitation, pricing information, except as required by law. No specified update or refresh date applied in the Service or on any related website, should be taken to indicate that all information in the Service or on any related website has been modified or updated.

                    <ol className="list-decimal list-outside text-2xl font-semibold">
                        <li className="mb-8">
                            <h3 className="text-2xl font-semibold mb-4"></h3>
                            <ol className="list-[lower-alpha] list-outside ml-6 text-base font-light">
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>

                            </ol>
                        </li>
                    </ol>

                    8. DELIVERY & PICK UP
                    8.1. We will use our best endeavours to deliver orders at your designated delivery date and time after the day your order is received by us and you have received confirmation of your order from us via email or equivalent. We are not responsible for any failure to deliver or relay in delivery caused by any event outside of our reasonable control.
                    8.2. In the event that we fail to deliver any products, you may elect to cancel your order in respect only of the products not delivered unless we have contacted you and you have agreed to delivery at a later date.
                    8.3. Where we fail to deliver or delay delivery of your order or part of your order or where any loss is incurred by you in relation to such delivery and is within our control, our liability shall be restricted to payment of the cost of replacing the order or part of the order, as we may determine (in our sole discretion).
                    8.4. Delivery is complete when the products reach the address you have specified for delivery. We will be responsible for arranging the delivery of products unless you arrange otherwise with us. It is your responsibility to have an individual present at your delivery address to collect the products. If there is no one present at the address you have specified for delivery, we will make two attempts at contacting you on the number you have provided to us. Should we fail to get a response from you at the last attempt, the products will be sent back to our store and we will inform you through email to arrange a redelivery at an extra cost or for you to pick up your order at a later time. Once the products are delivered at the initial time you have specified, it is no longer the responsibility of Pasteleria and we will not be held responsible for any damage that occurs to the products thereafter.
                    8.5. All our cakes are stored in the refrigerator overnight to ensure firmness and cakes with more two-tiers or more are reinforced with dowels for safe transport to the event location. If you have chosen to have your order picked up, we cannot be responsible for damage to any product once it has left our shop. Any repairs or replacement cakes, items and other products will be subject to additional fees.
                    8.6. Nothing in these terms entitles you to return the products as being surplus to your needs, or for any other reason other than set out herein.

                    <ol className="list-decimal list-outside text-2xl font-semibold">
                        <li className="mb-8">
                            <h3 className="text-2xl font-semibold mb-4"></h3>
                            <ol className="list-[lower-alpha] list-outside ml-6 text-base font-light">
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>

                            </ol>
                        </li>
                    </ol>

                    9. PAYMENT TERMS
                    CHARGES & INVOICING
                    9.1. You must pay us in full by credit card at the time that you submit your order.
                    9.2. If you pay by credit card you agree to indemnify us against any default by your credit card company to make payment to us in full.
                    9.3. We use "Stripe" to securely authorise credit card payments before any charges are made. For further information regarding "Stripe" and how it encrypts and processes transactions, please visit https://support.stripe.com. Alternate payment options are unavailable online, please email or phone us if you wish to pay via a different method.
                    ACCURACY OF BILLING AND ACCOUNT INFORMATION
                    9.4. We reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address. In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the e‑mail and/or billing address/phone number provided at the time the order was made. We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers or distributors.
                    9.5. You agree to provide current, complete and accurate purchase and account information for all purchases made at our store. You agree to promptly update your account and other information, including your email address and credit card numbers and expiration dates, so that we can complete your transactions and contact you as needed. For more detail, please review our Returns Policy.

                    <ol className="list-decimal list-outside text-2xl font-semibold">
                        <li className="mb-8">
                            <h3 className="text-2xl font-semibold mb-4"></h3>
                            <ol className="list-[lower-alpha] list-outside ml-6 text-base font-light">
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>

                            </ol>
                        </li>
                    </ol>

                    10. THIRD-PARTY LINKS
                    10.1. Certain content, products and services available via our Service may include materials from third-parties.
                    10.2. Third-party links on this site may direct you to third-party websites that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third-parties.
                    10.3. We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party websites. Please review carefully the third-party's policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.

                    <ol className="list-decimal list-outside text-2xl font-semibold">
                        <li className="mb-8">
                            <h3 className="text-2xl font-semibold mb-4"></h3>
                            <ol className="list-[lower-alpha] list-outside ml-6 text-base font-light">
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>

                            </ol>
                        </li>
                    </ol>

                    11. USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS
                    11.1. If, at our request, you send certain specific submissions (for example contest entries) or without a request from us you send creative ideas, suggestions, proposals, plans, or other materials, whether online, by email, by postal mail, or
                    otherwise (collectively, 'comments'), you agree that we may, at any time, without restriction, edit, copy, publish, distribute, translate and otherwise use in any medium any comments that you forward to us.
                    11.2. We are and shall be under no obligation to:
                    (a) Maintain any comments in confidence.
                    (b) Pay compensation for any comments.
                    (c) Respond to any comments.
                    11.3. We may, but have no obligation to, monitor, edit or remove content that we determine in our sole discretion are unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise objectionable or violates any party’s intellectual property or these Terms of Service.
                    11.4. You agree that your comments will not violate any right of any third-party, including copyright, trademark, privacy, personality or other personal or proprietary right. You further agree that your comments will not contain libelous or otherwise unlawful, abusive or obscene material, or contain any computer virus or other malware that could in any way affect the operation of the Service or any related website.
                    11.5. You may not use a false e‑mail address, pretend to be someone other than yourself, or otherwise mislead us or third-parties as to the origin of any comments. You are solely responsible for any comments you make and their accuracy. We take no responsibility and assume no liability for any comments posted by you or any third-party.

                    <ol className="list-decimal list-outside text-2xl font-semibold">
                        <li className="mb-8">
                            <h3 className="text-2xl font-semibold mb-4"></h3>
                            <ol className="list-[lower-alpha] list-outside ml-6 text-base font-light">
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>

                            </ol>
                        </li>
                    </ol>

                    12. PROHIBITED USES
                    12.1. In addition to other prohibitions as set forth in these Terms & Conditions, you are prohibited from using Pasteleria’s website or its content:
                    (a) For any unlawful purpose;
                    (b) To solicit others to perform or participate in any unlawful acts;
                    (c) To violate any international, federal, provincial or state regulations, rules, laws, or local ordinances;
                    (d) To infringe upon or violate our intellectual property rights or the intellectual property rights of others;
                    (e) To harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability;
                    (f) To submit false or misleading information;
                    (g) To upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet;
                    (h) To collect or track the personal information of others;
                    (i) To spam, phish, pharm, pretext, spider, crawl, or scrape;
                    (j) For any obscene or immoral purpose;
                    (k) To interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet.
                    12.2. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.

                    <ol className="list-decimal list-outside text-2xl font-semibold">
                        <li className="mb-8">
                            <h3 className="text-2xl font-semibold mb-4"></h3>
                            <ol className="list-[lower-alpha] list-outside ml-6 text-base font-light">
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>

                            </ol>
                        </li>
                    </ol>

                    13. WARRANTIES
                    13.1. We represent and warrant to you that:
                    (a) We have the right to sell the products to you.
                    (b) The products are not be subject to any undisclosed security or charge.
                    (c) You have the right to undisturbed possession of the products.
                    (d) We have made every effort to display as accurately as possible the colours and images of our products that appear on the website. We cannot guarantee that your computer monitor's display of any colour will be accurate.
                    13.2. We represent and warrant to you that the products:
                    (a) Are of acceptable quality having regard to their nature, the price, representations made by us and any statements made on packaging or labels.
                    (b) Are reasonably fit for the purpose that we represent.
                    (c) Supplied by description correspond with their description.
                    13.3. We do not warrant that the quality of any products, services, information, or other material purchased or obtained by you will meet your expectations, or that any errors in the Service will be corrected.

                    <ol className="list-decimal list-outside text-2xl font-semibold">
                        <li className="mb-8">
                            <h3 className="text-2xl font-semibold mb-4"></h3>
                            <ol className="list-[lower-alpha] list-outside ml-6 text-base font-light">
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>

                            </ol>
                        </li>
                    </ol>

                    14. CANCELLATIONS
                    14.1. Subject to clause 8 of this agreement.
                    (a) After we have accepted your order for the products, you may not cancel the order without our consent.
                    (b) We will not consent to cancellation if we have processed documentation in fulfilment of your order.
                    For more detail, please review our Refund Policy.

                    <ol className="list-decimal list-outside text-2xl font-semibold">
                        <li className="mb-8">
                            <h3 className="text-2xl font-semibold mb-4"></h3>
                            <ol className="list-[lower-alpha] list-outside ml-6 text-base font-light">
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>

                            </ol>
                        </li>
                    </ol>

                    15. USE OF YOUR INFORMATION
                    15.1. During the term of this agreement your use of the website will generate certain information that will be recorded electronically by us. Information of this type may include, for example, your IP address, your usage statistics, etc. We will also record your contact details, credit information and any related personal details that you supply or that we obtain independently.
                    15.2. You acknowledge that we, our employees, carriers, contractors and agents may use or disclose any of the information identified in the previous paragraph for purposes connected with the supply of the services to you. Examples of purposes connected with supply include, for example:
                    (a) administration of your account with Pasteleria or on the website.
                    (b) carrying out credit checks.
                    (c) keeping you up to date with new offers/changes at Pasteleria.
                    (d) sharing with contractors to enable you to use or facilitate your use of the website.
                    15.3. You may at any time make a written request to see any personal information that we hold or to ask us to correct any mistakes in that information.

                    <ol className="list-decimal list-outside text-2xl font-semibold">
                        <li className="mb-8">
                            <h3 className="text-2xl font-semibold mb-4"></h3>
                            <ol className="list-[lower-alpha] list-outside ml-6 text-base font-light">
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>

                            </ol>
                        </li>
                    </ol>

                    16. CONSENT TO RECEIPT OF ELECTRONIC MESSAGES
                    16.1. You agree by subscribing on this website that you expressly consent to your inclusion in our direct marketing database and accept that you may, as a result, receive regular electronic communications and promotional communications from us. You have the right to ask us at any time to stop sending online communications and promotional offers to you.

                    <ol className="list-decimal list-outside text-2xl font-semibold">
                        <li className="mb-8">
                            <h3 className="text-2xl font-semibold mb-4"></h3>
                            <ol className="list-[lower-alpha] list-outside ml-6 text-base font-light">
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>

                            </ol>
                        </li>
                    </ol>

                    17.INTELLECTUAL PROPERTY RIGHTS
                    17.1. All right, title and interest in all Intellectual Property in all concepts, systems, written, graphic and other material relating to Pasteleria’s online shop and its contents owned by, and shall at all times remain the exclusive property of Pasteleria, its licensors and the providers of products accessible through Pasteleria’s online shop, and is protected by New Zealand law. Nothing in these terms and conditions shall constitute any licence of intellectual property rights to the Customer.
                    17.2. Any infringement of our intellectual property rights will be fully enforced under New Zealand law.

                    <ol className="list-decimal list-outside text-2xl font-semibold">
                        <li className="mb-8">
                            <h3 className="text-2xl font-semibold mb-4"></h3>
                            <ol className="list-[lower-alpha] list-outside ml-6 text-base font-light">
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>

                            </ol>
                        </li>
                    </ol>

                    18. GENERAL TERMS
                    NO WAIVER
                    18.1. No delay, neglect or forbearance in taking enforcement action in relation to any provision of these terms will be a waiver, or in any way prejudice any right, of that party.
                    SEVERABILITY
                    18.2. If any part of these terms is held to be invalid, illegal or unenforceable, that part will be severed and the remainder of the terms will remain in full force and have full effect.
                    PRIVITY
                    18.3. Third parties may take the benefits of rights expressed to be for their benefit in accordance with the Contracts (Privity) Act 1982.

                    <ol className="list-decimal list-outside text-2xl font-semibold">
                        <li className="mb-8">
                            <h3 className="text-2xl font-semibold mb-4"></h3>
                            <ol className="list-[lower-alpha] list-outside ml-6 text-base font-light">
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>

                            </ol>
                        </li>
                    </ol>

                    19.TERMINATION
                    19.1. The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes. These Terms of Service are effective unless and until terminated by either you or us. You may terminate these Terms of Service at any time by notifying us that you no longer wish to use our Services, or when you cease using our site.
                    19.2. If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Service, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our Services (or any part thereof).

                    <ol className="list-decimal list-outside text-2xl font-semibold">
                        <li className="mb-8">
                            <h3 className="text-2xl font-semibold mb-4"></h3>
                            <ol className="list-[lower-alpha] list-outside ml-6 text-base font-light">
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>

                            </ol>
                        </li>
                    </ol>

                    20. JURISDICTION
                    20.1. The website is provided for use by New Zealand residents. We make no representations that the website complies with the laws of any country outside of New Zealand. If you access the website from outside New Zealand, you do so at your own risk and you are responsible for complying with the laws in the place where you use the website when purchasing products online.
                    20.2. This agreement is governed by New Zealand law and any legal action against us must be taken in a Court in New Zealand.

                    <ol className="list-decimal list-outside text-2xl font-semibold">
                        <li className="mb-8">
                            <h3 className="text-2xl font-semibold mb-4"></h3>
                            <ol className="list-[lower-alpha] list-outside ml-6 text-base font-light">
                                <li className="mb-4"></li>
                                <li className="mb-4"></li>

                            </ol>
                        </li>
                    </ol>

                    21. ASSIGNMENT
                    21.1. You may not assign any rights under this agreement except with our prior written consent.
                    21.2. We may assign our rights under this agreement without seeking your prior consent.

                    <ol className="list-decimal list-outside text-2xl font-semibold">
                        <li className="mb-8">
                            <h3 className="text-2xl font-semibold mb-4">NOTICES</h3>
                            <ol className="list-[lower-alpha] list-outside ml-6 text-base font-light">
                                <li className="mb-4">We will send all invoices and notices required under this agreement to the address (postal or email) that you have nominated as your preferred method of contact. It is your responsibility to ensure that you keep up us informed of any changes to your contact details.</li>
                                <li className="mb-4">
                                    You will be deemed to have received a notice:
                                    <ol className="list-[lower-roman] list-outside ml-12 text-base font-light">
                                        <li className="mb-4">Sent by email, at the time that we send it;</li>
                                        <li className="mb-4">Sent by post, four days after we send it.</li>
                                    </ol>
                                </li>
                            </ol>
                        </li>
                    </ol>
                    <ol className="list-decimal list-outside text-2xl font-semibold">
                        <li className="mb-8">
                            <h3 className="text-2xl font-semibold mb-4">AMENDMENTS</h3>
                            <ol className="list-[lower-alpha] list-outside ml-6 text-base font-light">
                                <li className="mb-4">Any new features or tools which are added to the current store are also subject to these Terms & Conditions. You can review the most current version of these Terms & Conditions at any time on this page.</li>
                                <li className="mb-4">We may change the terms of this agreement by sending you notice ("amendment notice"). It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.</li>
                            </ol>
                        </li>
                    </ol>

                </div>
            </section>
        </>
    )
}


