import React from 'react';
import { useCartActionContext } from "../components/cart-context";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { FormButton } from '../components/form-elements';
import { navigate } from "gatsby";
import { useColourPalettes } from "../hooks/use-colour-palettes";

export default function CakeItemDetail({ cake, hideActions }) {
    const { removeCake } = useCartActionContext();
    const image = getImage(cake.imageFile);

    return (
        <div className="flex flex-row py-2">
            <div className="p-3">
                <GatsbyImage image={image} alt="Pasteleria Cake" className="max-h-20 w-20 sm:max-h-36 mr-auto sm:w-36" objectFit="scale-down" />
            </div>
            <div className="flex flex-col p-2">
                <p className="uppercase text-base md:text-lg" >{cake.name}</p>
                <CakeProperty label={"size"} value={`${cake.selectedSize}`} />
                {!cake.selectedFlavour ? <></> : <CakeProperty label={"flavour"} value={cake.selectedFlavour} />}
                {!cake.selectedTexture ? <></> : <CakeProperty label={"texture"} value={cake.selectedTexture} />}
                {!cake.selectedDecoration ? <></> : <CakeProperty label={"decoration"} value={cake.selectedDecoration} />}
                {!cake.selectedSet1Colour ? <></> : <CakeProperty label={cake.set1ColourLabel} value={cake.selectedSet1Colour} color={cake.selectedSet1Colour} />}
                {!cake.selectedSet2Colour ? <></> : <CakeProperty label={cake.set2ColourLabel} value={cake.selectedSet2Colour} color={cake.selectedSet2Colour} />}
                {!cake.selectedSet3Colour ? <></> : <CakeProperty label={cake.set3ColourLabel} value={cake.selectedSet3Colour} color={cake.selectedSet3Colour} />}
                {!cake.selectedSet4Colour ? <></> : <CakeProperty label={cake.set4ColourLabel} value={cake.selectedSet4Colour} color={cake.selectedSet4Colour} />}
                {!cake.selectedSet5Colour ? <></> : <CakeProperty label={cake.set5ColourLabel} value={cake.selectedSet5Colour} color={cake.selectedSet5Colour} />}
                {!cake.selectedSet6Colour ? <></> : <CakeProperty label={cake.set6ColourLabel} value={cake.selectedSet6Colour} color={cake.selectedSet6Colour} />}
                {!cake.selectedSet7Colour ? <></> : <CakeProperty label={cake.set7ColourLabel} value={cake.selectedSet7Colour} color={cake.selectedSet7Colour} />}
                {!cake.selectedSet8Colour ? <></> : <CakeProperty label={cake.set8ColourLabel} value={cake.selectedSet8Colour} color={cake.selectedSet8Colour} />}
                {!cake.selectedSet9Colour ? <></> : <CakeProperty label={cake.set9ColourLabel} value={cake.selectedSet9Colour} color={cake.selectedSet9Colour} />}
                {!cake.selectedSet10Colour ? <></> : <CakeProperty label={cake.set10ColourLabel} value={cake.selectedSet10Colour} color={cake.selectedSet10Colour} />}
                {!cake.cakeTopperMessage ? <></> : <CakeProperty label={"cake inscription"} value={cake.cakeTopperMessage} />}
                {
                    hideActions ? <></> :
                        <div className="flex flex-col sm:flex-row pt-2">
                            <FormButton label={"EDIT"} onClick={() => navigate(`/cakes/${cake.code}?id=${cake.key}`)} size={"sm"} isSecondary={true} />
                            <FormButton label={"REMOVE"} onClick={() => removeCake(cake)} size={"sm"} isSecondary={true} />
                        </div>
                }
            </div>
        </div>
    )
}

const CakeProperty = ({ label, value, color }) => {
    const result = useColourPalettes().find(c => c.colour === color);
    const style = (result) ? { "backgroundColor": result.hex } : {};
    const colorElement = !!color ? <span className="h-3 w-3 ml-1 ring-1 ring-slate-500 rounded-lg" style={style} /> : <></>

    return (
        <div className="flex flex-wrap items-center font-light text-sm sm:text-base">
            <span className="text-xs sm:text-sm text-gray-500 lowercase mr-1">{label}:</span>
            <div className="flex flex-wrap items-top sm:items-center">
                <span className="leading-4 sm:leading-normal">{value}</span>
                {colorElement}
            </div>
        </div>
    )
}