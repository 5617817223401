import * as React from "react"
import Seo from "../components/seo"
import BrandHeading from "../components/brand-heading";

export default function Contact() {
    return (
        <>
            <Seo title="FAQ" />
            <BrandHeading title="frequently asked questions" />
            <section className="flex flex-wrap justify-evenly">
                <div className="text-justify font-sans-serif font-light max-w-3xl mx-auto">

                    <h3 className="text-2xl font-semibold">DELIVERY</h3>
                    <h3 className="text-base mb-4 font-semibold">WHICH AREAS DO YOU COVER?</h3>

                    <p className="pb-4">
                        We are currently delivering to all suburbs in Auckland. Our delivery fee is based on how far in kilometres your delivery address is from our North Shore studio.
                    </p>

                    <h3 className="text-2xl mt-4 font-semibold">ORDER CHANGES</h3>
                    <h3 className="text-base mb-4 font-semibold">CAN I STILL CHANGE MY ORDER AFTER I'VE PLACED IT?</h3>

                    <p className="pb-4">
                        For custom cake orders, we are unable to make any changes after your invoice has been sent. We always make sure that every detail in the design of your cake is discussed completely with you and finalised beforehand.
                    </p><p className="pb-4">

                        For next day orders, we are only able to accept changes to your order before 1pm on the day you placed your order. Please give us a call as soon as possible.
                    </p><p className="pb-4">
                        For all other orders, we are able to accept changes to your order if you notify us 48 hours before your pick-up/delivery date. Please contact us as soon as you can.
                    </p>

                    <h3 className="text-2xl mt-4 font-semibold">CANCELLATION POLICY</h3>
                    <h3 className="text-base mb-4 font-semibold">CAN I STILL CANCEL MY ORDER AND GET A REFUND?</h3>

                    <p className="pb-4">
                        Please refer to our Refund Policy here.
                    </p>

                    <h3 className="text-2xl mt-4 font-semibold">ALLERGIES</h3>
                    <h3 className="text-base mb-4 font-semibold">HOW DO YOU PREPARE YOUR GLUTEN-FRIENDLY AND VEGAN-FRIENDLY PRODUCTS?</h3>

                    <p className="pb-4">
                        We prepare our gluten and vegan-friendly products following procedures to prevent allergen cross-contamination. We use no additional gluten, eggs or dairy ingredients and have designated utensils when making our gluten and vegan-friendly products. However, while we strive to avoid cross-contamination in any way possible, we would like to emphasise that we do handle products containing gluten, dairy, eggs, nuts and soy in our kitchen. Please be advised that we cannot guarantee the effects of our gluten and vegan-friendly products to those who are allergic and have high sensitivities to gluten and dairy.
                    </p>

                    <h3 className="text-2xl mt-4 font-semibold">SERVING SIZES</h3>
                    <h3 className="text-base mb-4 font-semibold">HOW MANY PORTIONS CAN I GET FROM MY CAKE?</h3>

                    <p className="pb-4">
                        Unless otherwise stated in our cakes page, our single tier tall cakes are a standard 6 layers and approximately 6 inches high. If you want to customise the height and layers of your cake, feel free to email us at hello@pasteleria.co.nz.
                    </p>

                    <p className="pb-4">
                        <div>Our dessert serves are approx. 2 inches x 1 inch x 3 inches.</div>
                        <div>Our coffee serves are approx. 1 inch x 1 inch x 3 inches.</div>

                    </p>
                    <p className="pb-4">
                        We have provided a list of the number of cake servings you might expect from different sized cakes below. However, please note that while these numbers are a useful guide, the actual number of sizes will depend on the way your cake is cut on the day.
                    </p>
                    <p className="pb-4">
                        <div>4 inch – 8 dessert serves / 12 coffee serves.</div>
                        <div>6 inch – 32 dessert serves / 56 coffee serves.</div>
                        <div>4 + 6 inch – 44 dessert serves / 80 coffee serves.</div>
                        <div>8 inch – 52 dessert serves / 96 coffee serves.</div>
                        <div>6 + 8 inch – 84 dessert serves / 152 coffee serves.</div>
                        <div>4 + 6 + 8 inch – 96 dessert serves / 168 coffee serves.</div>
                        <div>6 + 8 + 10 inch – 160 dessert serves / 296 coffee serves.</div>
                        <div>4 + 6 + 8 + 10 inch – 172 dessert serves / 312 coffee serves.</div>
                    </p>

                    <h3 className="text-2xl mt-4 font-semibold">TRANSPORTATION</h3>
                    <h3 className="text-base mb-4 font-semibold">WHAT IS THE SAFEST WAY TO TRANSPORT MY CAKE?</h3>


                    <p className="pb-4">
                        Placing the cake on a flat surface is ideal so anywhere on the floor or in the boot of your car is recommended. You should prepare your vehicle so it is clean and free of items that could roll or slide into the cake. Please remember that you are responsible for the safe transportation of your cake. We are no longer liable or responsible for your order once it has left our studio and do not provide refunds if any mishap or accidental damage occurs in transit. We do everything in our power to provide a well-structured cake, but please note that cakes are very fragile – drive carefully and slowly.

                    </p>
                    <p className="pb-4">
                        You are more than welcome to collect your cakes from our studio. However, if your cake has two-tiers or more, we strongly recommend opting for delivery as it is a more complex and delicate job to handle.
                    </p>
                    <h3 className="text-2xl mt-4 font-semibold">STORAGE</h3>
                    <h3 className="text-base mb-4 font-semibold">HOW SHOULD I KEEP MY CAKE & HOW LONG CAN IT LAST?</h3>

                    <p className="pb-4">
                        All our cakes should be stored in the refrigerator (5°C) and taken out to be brought to room temperature half an hour prior to serving. Our cakes can last in the fridge for up to 3 days.
                    </p>
                </div>
            </section>
        </>
    )
}


