import Axios from "axios";

const config = {
  baseURL: `${process.env.GATSBY_API_URL}/`,
  headers: {
    'x-api-key': `${process.env.API_KEY}`
    , 'Content-Type': 'application/json'
  },
  timeout: 10000
}

export const httpClient = () => {
  return Axios.create(config);
}

