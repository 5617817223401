import React, { useState, useEffect, useRef } from "react"
import { Link, navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"
import pasteleriaHeading from "../images/pasteleria-heading.svg"
import "../css/index.css";
import { useQueryParam, StringParam } from "use-query-params";
import { FormButton, FormLinkButton } from "../components/form-elements";
import { useProducts } from "../hooks/use-products";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
export default function IndexPage() {
  let element = <></>;
  const [redirect] = useQueryParam("redirect", StringParam);
  switch (redirect) {
    case "success": navigate("/checkout-success/"); break;
    case "cancel": navigate("/checkout-review/"); break;
    default:
      element = <>
        <Seo title="Home" />
        <BannerDesktop></BannerDesktop>
        <BannerMobile></BannerMobile>
      </>;
      break;
  }
  return (<>{element}</>)
}

function BannerDesktop() {
  return (
    <main className="md:flex hidden h-[calc(100vh_-_13rem)] min-h-[15rem]" >

      <div className="md:flex hidden items-center max-w-2xl lg:max-w-3xl mx-auto
        h-full max-h-[30rem]">
        <CakeBanner />
        <div className="w-5/12 text-left
            ml-5 lg:ml-10 
            flex flex-col justify-around 
            h-full max-h-64">
          <div>
            <WelcomeMessage />
            <PasteleriaHeading />
          </div>
          <OrderNow />
        </div>
      </div>
    </main>
  );
}

function BannerMobile() {
  return (
    <main className="md:hidden flex flex-col justify-center items-center h-[calc(100vh_-_10rem)]">
      <WelcomeMessage />
      <div className="py-6">
        <PasteleriaHeading />
      </div>
      <CakeBanner />
      <div className="my-4 mt-14">
        <OrderNow />
      </div>
      {/* <StaticImage src="../images/arrow-thin-down.svg" className="animate-bounce h-6 mt-10 pb-2" alt="Order below" /> */}
    </main>
  );
}

function WelcomeMessage() {
  return (
    <>
      {/* <h1 className="text-3xl md:text-6xl font-normal font-berkshire md:-m-3 pt-4 md:pt-0">
        Kia Ora
      </h1> */}
      <p className="pt-0 pb-2 text-lg md:text-2xl font-normal">
        Welcome to
      </p>
    </>
  );
}

function PasteleriaHeading() {
  return (
    <img src={pasteleriaHeading} className="Header max-h-[calc(10vh)]" alt="Pasteleria" />
  );
}

function CakeBanner() {
  const products = useProducts()

  const images = products.filter(x =>
    x.name == "Lemon Meringue" ||
    x.name == "Sails").map(cake => {
      const image = getImage(cake.localImage[0])
      return <div key={cake.id} className="banner-image">
        <GatsbyImage image={image} alt={cake.name} />
      </div>
    })
  return (
    <div className="w-11/12 md:w-7/12 flex justify-center">
      <div id="cf" className="flex items-center">
        {images}
        <div className="banner-image">
          <StaticImage
            src="../images/cakes/gallery_starwars.png"
            quality={100}
            height={500}
            placeholder="blurred"
            formats={["auto", "webp", "avif"]}
            alt="Pasteleria Cake" />
        </div>
      </div>
    </div>
  );
}

function OrderNow() {
  return (<FormLinkButton label={"ORDER NOW"} to={"/cakes"} isSecondary={true} className={"font-normal w-fit"} />);
}






