import * as React from "react"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import BrandHeading from "../components/brand-heading";
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function Gallery({ data }) {
    const cakes = data.allCakeGalleryJson.nodes.map((cake, i) =>        
            <CakeCard key={i} cake={cake} />
        )
    return (
        <>
            <Seo title="Gallery" />
            <BrandHeading title="gallery" />
            <section class="overflow-hidden ">
                <div class="container px-5 py-2 mx-auto">
                    <div class="flex flex-wrap -m-1 md:-m-2">
                    {cakes}
                    </div>
                </div>
            </section>
        </>
    )
}

export const query = graphql`
query GalleryCakesQuery {
    allCakeGalleryJson {
      nodes {
        imageFile{
          publicURL
          childImageSharp {
              gatsbyImageData(
                height: 400
                placeholder: BLURRED
                )
          }                
        }                                   
      }
    }
  }`


function CakeCard({ cake }) {
    const image = getImage(cake.imageFile)
    return (
        <div class="flex flex-wrap w-1/2 lg:w-1/3">
            <div class="w-full p-2 md:p-3 transition-all hover:p-0">
                <GatsbyImage image={image} alt="Pasteleria Cake" className="rounded-md" />
            </div>
        </div>
    )
}