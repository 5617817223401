import React, { useState, useEffect } from "react"
import Seo from "../components/seo"
import BrandHeading from "../components/brand-heading";
import { FormButton } from '../components/form-elements';
import { useCartItemsContext } from "../components/cart-context";
import { useCheckoutContext } from "../components/checkout-context";
import { toCurrencyFormat, toCurrencyNzFormat } from "../helpers/currency-format-helper";
import { toDateFormat, toTimeFormat } from "../helpers/datetime-format-helper";
import { aggregateLineItems } from "../helpers/stripe-helper";
import { httpClient } from "../helpers/http-client-helper";
import usePickupAddress from "../hooks/use-pickup-address";
import { metadataFromCartItems } from "../helpers/metadata-helper";
import { FULFILLMENT } from "../constants";
import { navigate } from "gatsby";
import CakeItemDetail from "../components/cake-item-detail";
import useCost from "../hooks/use-cost";

export default function CheckoutReview() {
    return (
        <>
            <Seo title="Checkout Review" />
            <BrandHeading title="Checkout" />
            <section>
                <div>
                    <h1 className="text-center text-xl">REVIEW & PURCHASE</h1>
                    <p className="text-center">Please review your information and purchase when you're ready.</p>
                    <h1 className="text-lg font-bold mt-8 mb-4">Order Summary</h1>
                    <CartItems />
                    <Fulfillment />
                    <Notes />
                    <SubTotal />
                    <Total />
                    <PurchaseForm />
                </div>
            </section>
        </>
    )
}


const PurchaseForm = () => {
    const cartItems = useCartItemsContext();
    const { customer } = useCheckoutContext();
    const { shippingRate, hasCakeTopperExtra, messageTopperProduct } = useCost();
    const [loading, setLoading] = useState(false);
    const lineItemCollection = cartItems.map(x => { return { price: x.selectedPriceId, quantity: x.quantity, hasCakeTopperExtra: hasCakeTopperExtra(x) } });
    const lineItemExtrasCollection = lineItemCollection.filter(x => x.hasCakeTopperExtra).map(x => { return { price: messageTopperProduct.id, quantity: x.quantity } });
    const lineItems = aggregateLineItems(lineItemCollection).concat(aggregateLineItems(lineItemExtrasCollection));
    // console.log("cartItems", cartItems);
    const params = {
        line_items: lineItems,
        shipping_rate: shippingRate.id,
        email: customer.email,
        metadata: metadataFromCartItems(cartItems)
    }

    const formSubmit = async (e, params) => {
        e.preventDefault();
        try {
            setLoading(true)
            const result = await httpClient().post("/create-checkout-session", params);
            window.location.replace(result.data);
        } catch (error) {
            console.error(error);
            setLoading(false)
        }
    };

    return (

        <div className="max-w-xs mx-auto my-12">
            <FormButton className="w-full mb-4" label={"PURCHASE"} onClick={(e) => formSubmit(e, params)} disabled={loading} isProcessing={loading} />
            <FormButton className="w-full mb-4" label={"BACK"} onClick={() => navigate(`/checkout-notes/`)} isSecondary={true} />
        </div>
    )
}

const Fulfillment = () => {
    const { fulfillment } = useCheckoutContext();
    return (
        <div>
            {(fulfillment.fulfillmentType === FULFILLMENT.DELIVERY) ? <DeliveryDetails /> : <PickupDetails />}
            <hr className="my-4" />
        </div>
    )
}


const Notes = () => {
    const { orderNotes } = useCheckoutContext();
    return (
        <div>
            <div className="flex flew-row justify-between">
                <div className="basis-1/3">
                    <span>
                        Notes
                    </span>
                </div>
                <div className="basis-2/3 text-right">
                    <span>
                        {orderNotes && orderNotes.note?.trim() ? orderNotes.note : "None"}
                    </span>
                </div>
            </div>
            <div className="flex flew-row justify-between">
                <div className="basis-1/3">
                    <span>Surprise gift</span>
                </div>
                <div className="basis-2/3 text-right">
                    <span>{orderNotes.isSurprise ? "Yes" : "No"}</span>
                </div>
            </div>


            <hr className="my-4" />
        </div>
    )
}

const DeliveryDetails = () => {
    const { customer, fulfillment } = useCheckoutContext();
    const date = new Date(fulfillment.collectionDate);
    const dateLessHour = new Date(fulfillment.collectionDate);
    return (
        <div className="flex flew-row justify-between">
            <div className="basis-1/3">
                <span>Delivery details</span>
            </div>
            <div className="basis-2/3 flex flex-col text-right">
                <span className="font-bold">{customer.name}</span>
                <span>{customer.email}</span>
                <span>{customer.phone}</span>
                <span>{toDateFormat(date)}</span>
                <span><span className="font-light">delivery between </span> {toTimeFormat(dateLessHour.setHours(dateLessHour.getHours() - 1))} - {toTimeFormat(date)}</span>
                <span><span className="font-light">delivery at </span> {fulfillment.address}</span>
            </div>
        </div>
    )
}

const PickupDetails = () => {
    const { customer, fulfillment } = useCheckoutContext();
    const date = new Date(fulfillment.collectionDate);
    const dateLessHour = new Date(fulfillment.collectionDate);
    return (
        <div className="flex flew-row justify-between">
            <div className="basis-1/3">
                <span>Pick-up details</span>
            </div>
            <div className="basis-2/3 flex flex-col text-right">
                <span className="font-bold">{customer.name}</span>
                <span>{customer.email}</span>
                <span>{customer.phone}</span>
                <span>{toDateFormat(date)}</span>
                <span><span className="font-light">pick up between </span> {toTimeFormat(dateLessHour.setHours(dateLessHour.getHours() - 1))} - {toTimeFormat(date)}</span>
                <span><span className="font-light">pick up at </span> {usePickupAddress()}</span>
            </div>
        </div>
    )
}


const SubTotal = () => {
    const { shippingRate, subTotal } = useCost()

    const { fulfillment } = useCheckoutContext();
    const isDelivery = (fulfillment.fulfillmentType === FULFILLMENT.DELIVERY);

    return (
        <div>
            <div className="flex flew-row justify-between">
                <div className="basis-1/3">
                    Subtotal
                </div>
                <div className="basis-2/3 text-right">
                    {toCurrencyFormat(subTotal)}
                </div>
            </div>
            <div className="flex flew-row justify-between">
                <div className="basis-1/3">
                    {isDelivery ? "Delivery" : "Pick-up"}
                </div>
                <div className="basis-2/3 text-right">
                    {isDelivery ? toCurrencyFormat(shippingRate.amount) : "$0.00"}
                </div>
            </div>
            <hr className="my-4" />
        </div>
    )
}

const Total = () => {
    const { checkOutTotal, gstCost } = useCost();

    return (
        <div>
            <div className="flex flew-row justify-between items-center">
                <div className="basis-1/3 flex flex-col">
                    <span className="text-xl">TOTAL</span>
                    <span className="text-sm text-gray-500">Includes {toCurrencyFormat(gstCost)} gst</span>
                </div>
                <div className="basis-2/3 text-right text-3xl">
                    {toCurrencyNzFormat(checkOutTotal)}
                </div>
            </div>
            <hr className="my-4" />
        </div>
    )
}

const CartItems = () => {
    const cartItems = useCartItemsContext()
    const { getCakeAmount } = useCost()
    return (
        <div>
            <hr className="my-4" />
            {
                cartItems.map(cake =>
                    <div
                        key={cake.key}
                        className="flex flew-row justify-between"
                    >
                        <CakeItemDetail cake={cake} hideActions={true} />
                        <div className="py-2 pt-5 text-md">{toCurrencyFormat(getCakeAmount(cake))}</div>
                    </div>)
            }
            <hr className="my-4" />
        </div>
    )
}

