import * as React from "react"
import Seo from "../components/seo"
import BrandHeading from "../components/brand-heading";

export default function Contact() {
    return (
        <>
            <Seo title="Privacy Policy" />
            <BrandHeading title="privacy policy" />
            <section className="flex flex-wrap justify-evenly">
                <div className="text-justify font-sans-serif font-light max-w-3xl mx-auto">

                    <h3 className="text-2xl font-semibold"></h3>
                    <h3 className="text-base mb-4 font-semibold"></h3>

                    <p className="pb-4">

                    </p>

                </div>
            </section>
        </>
    )
}


