import React from 'react';
import { useCartItemsContext } from "./cart-context";
import { toCurrencyFormat } from "../helpers/currency-format-helper";
import { getMessageTopperPrice } from "../helpers/cake-message-price-helper";
import useCost from "../hooks/use-cost";

export default function CartSummary() {
    const { subTotal } = useCost()

    return (
        <div className="py-3 px-6 text-white bg-pas-peach
                flex justify-between
            ">
            <span>Cart Total</span><span>{toCurrencyFormat(subTotal)}</span>
        </div>
    )
}
