import { useCartItemsContext } from "../components/cart-context";
import { getMessageTopperPrice } from "../helpers/cake-message-price-helper";
import { useCheckoutContext } from "../components/checkout-context";
import { FULFILLMENT } from "../constants";

export default function useCost() {

    const { fulfillment } = useCheckoutContext();
    const messageTopperProduct = getMessageTopperPrice();
    const cartItems = useCartItemsContext();

    const hasCakeTopperExtra = (cake) => !!(cake.cakeTopperType && cake.cakeTopperMessage);

    const getCakeWithExtrasAmount = (cake) => hasCakeTopperExtra(cake) ? cake.selectedPrice + messageTopperProduct.price : cake.selectedPrice

    const getCakeAmount = (cake) => cake.quantity * getCakeWithExtrasAmount(cake);

    const isShippingDelivery = fulfillment.fulfillmentType === FULFILLMENT.DELIVERY;

    const shippingRate = isShippingDelivery ? fulfillment.shippingRate : { amount: 0, id: "" };
    const subTotal = cartItems ? cartItems.map(cake => getCakeAmount(cake)).reduce((prev, cur) => prev + cur, 0) : 0;
    const checkOutTotal = subTotal + shippingRate?.amount ;
    const gstCost = checkOutTotal - checkOutTotal / 1.15;

    return {
        getCakeAmount,
        hasCakeTopperExtra,        
        getCakeWithExtrasAmount,
        isShippingDelivery,
        messageTopperProduct,
        shippingRate,
        gstCost,
        checkOutTotal,
        subTotal,
    }
}
