import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export default function MainCakeImage() {
  return (
    <StaticImage
      src="../images/cakes/pasteleria_wedding_watercolour.jpg"
      alt="Pasteleria Wedding Watercolour"
      placeholder="blurred"  
      className="max-w-xs"
    />
  )
}