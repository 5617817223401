const requiredValidation = (text, name) => (!text || text.trim()=="") ? `${name} is required.` : null;
const emailValidation = (email) => (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) ? `Invalid email format.` : null;
const dateValidation = (date) => {

    Date.prototype.addDays = function (days) {
        let date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }

    return (new Date(date).setHours(0, 0, 0, 0) < new Date().addDays(2).setHours(0, 0, 0, 0)) ? `At least 2 days lead time expected.` : null;
};

export { requiredValidation, emailValidation, dateValidation }