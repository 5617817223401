import * as React from "react"
import Seo from "../../components/seo"
import { graphql } from "gatsby"
import BrandHeading from "../../components/brand-heading";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import CakeForm from '../../components/cake-form.js';
import { useQueryParam, NumberParam, StringParam } from "use-query-params";
import { useCartItemsContext } from "../../components/cart-context";
import { usePrices } from "../../hooks/use-prices";

export default function CakeDetail({ data }) {  
  const cartItems = useCartItemsContext();
  const [cakeKey] = useQueryParam("id", StringParam);
  const prices = usePrices();
  const cakePrices = prices.filter(x => x.product.id === data.cake?.id);
  const meta = data.cake?.metadata;

  const getArray = (v) => {
    const result = v && v.trim() !== "" ? v.split(",") : undefined;
    if (result) return result.map(x => x.trim());
    return result;
  };

  let cake = {
    ...data.cake
    , imageFile: data.cake?.localImage[0]
    , code: meta?.code
    , textures: getArray(meta?.textures)
    , flavours: getArray(meta?.flavours)
    , decorations: getArray(meta?.decorations)
    , cakeShapes: getArray(meta?.cakeShapes)
    , set1Colours: getArray(meta?.set1Colours)
    , set2Colours: getArray(meta?.set2Colours)
    , set3Colours: getArray(meta?.set3Colours)
    , set4Colours: getArray(meta?.set4Colours)
    , set5Colours: getArray(meta?.set5Colours)
    , set6Colours: getArray(meta?.set6Colours)
    , set7Colours: getArray(meta?.set7Colours)
    , set8Colours: getArray(meta?.set8Colours)
    , set9Colours: getArray(meta?.set9Colours)
    , set10Colours: getArray(meta?.set10Colours)
    , set1ColourLabel: meta?.set1ColourLabel
    , set2ColourLabel: meta?.set2ColourLabel
    , set3ColourLabel: meta?.set3ColourLabel
    , set4ColourLabel: meta?.set4ColourLabel
    , set5ColourLabel: meta?.set5ColourLabel
    , set6ColourLabel: meta?.set6ColourLabel
    , set7ColourLabel: meta?.set7ColourLabel
    , set8ColourLabel: meta?.set8ColourLabel
    , set9ColourLabel: meta?.set9ColourLabel
    , set10ColourLabel: meta?.set10ColourLabel
    , options: cakePrices.map(x => { return { id: x.id, size: x.description, price: (x.unit_amount) / 100 }; })
  }

  if (cakeKey) {
    const item = cartItems.find(x => x.key == cakeKey);
    if (item) cake = item;
  }
  const image = getImage(cake.imageFile);

  return (
    <>
      <Seo title={`Cake - ${cake.name}`} />
      <main className="mx-auto max-w-xl">
        <BrandHeading title="cake" />
        <div className="text-center mb-4">          
          <GatsbyImage image={image} alt="Pasteleria Cake" className="mx-auto" width={200} height={200}  />
        </div>
        <div className="text-left">
          <CakeForm cake={cake} />
        </div>
      </main>
    </>
  )
}

export const query = graphql`
  query Project($metadata__code: String) {
    cake: stripeProduct(            
      active: {eq: true}
      metadata: {type: {eq: "cake"}, code: {eq: $metadata__code}}
    )  {
      active
      id
      images
      livemode
      name
      description
      object
      
      type
      
      localImage {
        publicURL
        childImageSharp {
            gatsbyImageData(
              
              placeholder: BLURRED
              )
        }                
      } 
      metadata {
        code
        type
        flavours
        textures                
        decorations
        cakeShapes
        set1Colours
        set2Colours
        set3Colours
        set4Colours
        set5Colours
        set6Colours
        set7Colours
        set8Colours
        set9Colours
        set10Colours
        
        
        set1ColourLabel
        set2ColourLabel
        set3ColourLabel
        set4ColourLabel
        set5ColourLabel
        set6ColourLabel
        set7ColourLabel
        set8ColourLabel
        set9ColourLabel
        set10ColourLabel
       
     
      }
    }
  }`