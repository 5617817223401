import { usePrices } from "../hooks/use-prices";
import { useProductExtras } from "../hooks/use-product-extras";

export const getMessageTopperPrice = () => {

    const prices = usePrices();
    const products = useProductExtras();
    const cakeTopper = products[0];
  
    const cakePrices = prices.filter(x => x.product.id === cakeTopper.id);
    const options = cakePrices.map(x => { return { id: x.id, size: x.description, price: (x.unit_amount) / 100 }; })
  
    return options[0];
  
  }
  