import React, { useState, useEffect } from "react"
import "../css/header.css"
import { Link, navigate } from "gatsby"

import logo from "../images/logo.svg";
import menu from "../images/menu.svg";
import cart from "../images/shopping-cart.svg";
import close from "../images/close.svg";
import usersolidcircle from "../images/user-solid-circle.svg";

import { useCartItemsContext } from "../components/cart-context"

function NavLink({ to, children, closeMenu }) {
  return (
    <li className="font-semibold text-center text-md md:text-base lg:text-lg py-2 md:mx-3 lg:mx-6 md:py-0 lowercase">
      <Link to={to} onClick={() => closeMenu(true)} className="whitespace-nowrap">
        {children ?? to}
      </Link>
    </li>
  )
}

function LoginLink() {
  return (
    <li>
      <Link to="/" className="hidden md:block md:mx-3 lg:mx-6">
        <img src={usersolidcircle} className="App-user-icon" alt="User Login" />
      </Link>
    </li>
  )
}

function HomeLogoLink() {
  return (
    <div className=" min-h-[calc(3.08rem)]">
      <Link to="/">
        <img src={logo} className="App-logo mx-auto" alt="logo" />
      </Link>
    </div>
  )
}


const Header = () => {
  const [menuClosed, toggleMenuClosed] = useState(() => true);
  const cartItems = useCartItemsContext();

  return (
    <header className="App-header py-4 lg:pb-6 lg:pt-6">
      <div className="flex justify-between items-center md:hidden">
        <Link to="/cart">
          <div className="flex">
            <img src={cart} className="App-user-icon" alt="Cart" /><span className="inline-block align-top text-sm">({cartItems.length})</span>
          </div>
        </Link>
        <HomeLogoLink />
        <button onClick={() => toggleMenuClosed((state) => !state)}>
          <img src={menuClosed ? menu : close} className="App-user-icon" alt="Menu" />
        </button>
      </div>
      <ul className={`${menuClosed ? "hidden md:flex" : "flex"} flex-col md:flex-row pt-8 md:pt-0 divide-y divide-dashed divide-pas-orange md:divide-y-0 md:items-center md:pb-4"`}>
        <div className="basis-5/12 flex justify-start w-full">
          <div className={`${menuClosed ? "hidden md:flex" : "flex"} 
           w-full flex-col md:flex-row pt-8 md:pt-0 divide-y divide-dashed divide-pas-orange md:divide-y-0 md:items-center md:pb-4"`}>
            <NavLink to="/" closeMenu={toggleMenuClosed}>Home</NavLink>
            <NavLink to="/cakes" closeMenu={toggleMenuClosed}>Cakes</NavLink>
            <NavLink to="/customise" closeMenu={toggleMenuClosed}>Customise</NavLink>
            <NavLink to="/gallery" closeMenu={toggleMenuClosed}>Gallery</NavLink>
          </div>
        </div>
        <div className="basis-2/12  hidden md:block md:grow"><HomeLogoLink /></div>
        <div className="basis-5/12 flex justify-end w-full">
          <div className={`${menuClosed ? "hidden md:flex" : "flex"} 
           w-full flex-col md:flex-row md:pt-0 divide-y divide-dashed divide-pas-orange md:divide-y-0 md:items-center md:pb-4"`}>
            <NavLink to="/about" closeMenu={toggleMenuClosed}>About Us</NavLink>
            <NavLink to="/faq" closeMenu={toggleMenuClosed}>FAQ</NavLink>
            <NavLink to="/contact" closeMenu={toggleMenuClosed}>Contact</NavLink>
            <NavLink to="/cart" closeMenu={toggleMenuClosed}>Cart({cartItems.length})</NavLink>
          </div>
        </div>
      </ul>
    </header>
  )
}

export default Header
