import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

function Cake({ cake }) {
    const sortedPrice = cake.options.map(x => x.price).sort((a, b) => a - b)
    const lowestPrice = (sortedPrice) ? sortedPrice[0] : 0
    const image = getImage(cake.imageFile)

    return (
        <div className="flex flex-col items-center md:px-4 mb-12 sm:mb-16">
            <GatsbyImage image={image} alt="Pasteleria Cake" className="h-60 w-60 sm:h-80 sm:w-80" />
            <div className="text-center md:text-left max-w-2xs px-2 sm:py-4 flex flex-col">
                <div className="text-center">
                    <h3 className="font-semibold text-2xl uppercase">{cake.name}</h3>
                    <p className="font-light">from ${lowestPrice}</p>
                </div>
            </div>

        </div>
    )
}

export default Cake;