import React, { useState } from 'react';
import { usePlacesWidget } from "react-google-autocomplete";
import { Link } from "gatsby"
import useUpdateEffect from "../hooks/use-update-effect";
import { useColourPalettes } from "../hooks/use-colour-palettes";
import { StaticImage } from "gatsby-plugin-image";

const FormInput = ({ type, label, value, onChange, onBlur, placeholder, disabled, validations, maxLength, min, max }) => {
    const [error, setError] = useState(() => null);
    useUpdateEffect(() => {
        if (validations?.length > 0) {
            const result = validations.find(f => f(value));
            setError(() => result ? result(value) : null);
        }
    }, [value])
    return (
        <label className="block">
            <span className="font-semibold">{label}</span>
            <input
                value={value}
                onChange={(e) => onChange(() => e.target.value)}
                onBlur={onBlur}
                type={type}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-pas-peach-dark focus:ring focus:ring-pas-peach-darkest focus:ring-opacity-50"
                placeholder={placeholder}
                disabled={disabled}
                maxLength={maxLength}
                min={min}
                max={max} />
            <p className="text-red-500 text-xs mt-2 ml-2">{error}</p>

        </label>
    )
}

export const FormInputText = ({ label, value, onChange, placeholder, disabled, validations }) => {
    return (
        <FormInput type={"text"} label={label} value={value} onChange={onChange} placeholder={placeholder} disabled={disabled} validations={validations} />
    )
}

export const FormInputNumber = ({ label, value, onChange, placeholder, disabled, validations, onBlur, maxLength, min, max }) => {
    return (
        <FormInput type={"number"} label={label} value={value} onChange={onChange} placeholder={placeholder}
            disabled={disabled} validations={validations} onBlur={onBlur} maxLength={maxLength}
            min={min} max={max} />
    )
}

export const FormInputEmail = ({ label, value, onChange, placeholder, disabled, validations }) => {
    return (
        <FormInput type={"email"} label={label} value={value} onChange={onChange} placeholder={placeholder} disabled={disabled} validations={validations} />
    )
}

export const FormInputDate = ({ label, value, onChange, placeholder, disabled, validations }) => {
    return (
        <FormInput type={"date"} label={label} value={value} onChange={onChange} placeholder={placeholder} disabled={disabled} validations={validations} />
    )
}

export const FormInputDateTime = ({ label, value, onChange, placeholder, disabled, validations }) => {
    return (
        <FormInput type={"datetime-local"} label={label} value={value} onChange={onChange} placeholder={placeholder} disabled={disabled} validations={validations} />
    )
}

export const FormInputTel = ({ label, value, onChange, placeholder, disabled, validations }) => {
    return (
        <FormInput type={"tel"} label={label} value={value} onChange={onChange} placeholder={placeholder} disabled={disabled} validations={validations} />
    )
}

export const FormButton = ({ label, onClick, disabled, size, isSecondary, className, isProcessing }) => {
    let padding;
    let fontWeight;
    let secondary = `bg-pas-white text-pas-gray 
    shadow-md shadow-gray-100                
    border border-gray-300 rounded-md                        
    hover:shadow-gray-300`;
    let primary = ` bg-pas-peach-dark text-white 
    shadow-md shadow-gray-100                
    border border-transparent rounded-md                        
    hover:shadow-gray-300`;
    let theme = isSecondary ? secondary : primary;
    let cursor = isProcessing ? "cursor-progress" : disabled ? "cursor-not-allowed" : "";

    switch (size) {
        case "sm":            
            padding = "pt-1 px-2 mr-2 mb-2";
            fontWeight = "font-light text-base sm:text-lg"
            break;
        default:
            padding = "py-3 px2";
            fontWeight = "font-medium"
            break;
    }
    return (
        <button
            type="button"
            onClick={onClick}
            disabled={disabled}
            className={`            
                disabled:transform-none disabled:transition-none disabled:bg-gray-300 disabled:shadow-gray-400
                focus:outline-none focus:ring-2 focus:ring-offset-2 
                focus:ring-pas-peach-darkest
                ${padding}
                ${fontWeight}
                ${theme}
                ${cursor}
                ${className}`}>

            <span className="inline-block align-middle">
                {label}
                {isProcessing ? <ProcessIcon /> : <></>}
            </span>

        </button>
    )
}

const ProcessIcon = () =>
    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline-block mb-1 animate-spin" viewBox="0 0 20 20" fill="currentColor">
        <path
            fillRule="evenodd"
            d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
            clipRule="evenodd" />
    </svg>



export const FormLinkButton = ({ label, to, className, isSecondary }) => {
    let secondary = `bg-pas-white text-pas-gray 
    shadow-md shadow-gray-100                
    border border-gray-300 rounded-md                        
    hover:shadow-gray-300`;
    let primary = ` bg-pas-peach-dark text-white 
    shadow-md shadow-gray-100                
    border border-transparent rounded-md                        
    hover:shadow-gray-300`;
    let theme = isSecondary ? secondary : primary;
    return (
        <Link
            to={to}
            className={`                
                font-medium text-center                
                py-3 px-8
                focus:outline-none focus:ring-2 focus:ring-offset-2 
                focus:ring-pas-peach-darkest
                ${className}
                ${theme}
                `}>
            {label}</Link>
    )
}

export const FormInputAddressAutoComplete = ({ label, value, onChange, validations }) => {

    const [error, setError] = useState(() => null);
    const [isAuckland, setIsAuckland] = useState(() => false);

    useUpdateEffect(() => {
        if (validations?.length > 0) {
            const result = validations.find(f => f(value));
            const resultError = result ? result(value) : null;
            const outsideAucklandError = "Sorry, we only deliver in Auckland currently.";
            setError(() => resultError ? resultError : (!isAuckland ? outsideAucklandError : null));

        }
    }, [value, isAuckland])

    const { ref } = usePlacesWidget({
        apiKey: process.env.GOOGLE_MAPS_API_KEY,
        onPlaceSelected: (place) => {
            const auckland = place?.address_components.find(c => {
                const locality = c.types.find(t => t == "locality");
                const area = c.types.find(t => t == "administrative_area_level_1");
                return ((locality || area) && c?.short_name == "Auckland") ? c : null;
            })
            setIsAuckland(!!auckland);
            onChange(() => ref.current.value);
        },
        options: {
            types: ["(cities)"],
            componentRestrictions: { country: "nz" },
            fields: ["address_components"],
            types: ["address"],
        },
    });

    return (
        <label className="block">
            <span className="font-semibold">{label}</span>
            <input
                ref={ref}
                defaultValue={value}
                type="text"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-pas-peach-dark focus:ring focus:ring-pas-peach-darkest focus:ring-opacity-50"
            />
            <p className="text-red-500 text-xs mt-2 ml-2">{error}</p>
        </label>

    )
}

export const FormSelect = ({ label, value, onChange, placeholder, options, error }) => {
    if (!options) return (<></>);
    return (
        <label className="block">
            <span className="font-semibold">{label}</span>
            <select value={value}
                placeholder={placeholder}
                onChange={(e) => onChange(() => e.target.value)}
                className="
                    block
                    w-full
                    mt-1
                    rounded-md
                    border-gray-300
                    shadow-sm
                    focus:border-pas-peach-dark focus:ring focus:ring-pas-peach-darkest focus:ring-opacity-50">
                {options.map(x => <option key={x.key ?? x.value} value={x.value}>{x.label}</option>)}
            </select>
            <p className="text-red-500 text-xs mt-2 ml-2">{error}</p>
        </label>
    )
}

export const FormTextArea = ({ label, value, onChange }) => {
    return (
        <label className="block">
            <span className="font-semibold">{label}</span>
            <textarea
                value={value}
                onChange={(e) => onChange(() => e.target.value)}
                className="
                    mt-1
                    block
                    w-full
                    rounded-md
                    border-gray-300
                    shadow-sm
                    focus:border-pas-peach-dark focus:ring focus:ring-pas-peach-darkest focus:ring-opacity-50" rows="3"></textarea>
        </label>
    )
}

export const FormRadioGroup = ({ label, value, onChange, groupName, options, inline }) => {
    return (
        <div className="flex flex-col">
            {(label) ? <span className="font-semibold mb-2">{label}</span> : <></>}
            <label className="inline-flex">
                <div className={!inline ? "flex flex-col gap-2" : "flex flex-wrap gap-2"}>
                    {options.map(
                        (x, xi) =>
                            <label key={x.value} className="inline-flex items-start md:items-center pr-6">
                                <input
                                    value={x.value}
                                    name={groupName}
                                    type="radio"
                                    checked={x.value == value}
                                    onChange={(e) => onChange(() => e.target.value)}
                                    className="block rounded-full 
                                    h-5 w-5
                                    text-pas-peach-darkest
                                    transition duration-200
                                    bg-no-repeat bg-center bg-contain
                                    checked:bg-pas-peach-darkest checked:border-pas-peach-darkest 
                                    border-gray-300 shadow-sm 
                                    focus:border-pas-peach-dark focus:bg-pas-peach-darkest focus:ring focus:ring-pas-peach-darkest focus:ring-opacity-50"
                                />
                                {(x.labels?.length > 0) ? <div className="flex flex-wrap pt-2">
                                    {x.labels.map((y, yi) => <span key={yi} className={yi > 0 ? "ml-2 sm:block font-light text-sm sm:text-base leading-3" : "ml-2 w-full sm:w-fit inline-block align-middle"}>{y}</span>)}
                                </div> : <></>}

                            </label>
                    )}
                </div>
            </label>
        </div>
    )
}

export const FormCheckbox = ({ label, value, onChange }) => {
    return (
        <div className="block">
            <div className="mt-2">
                <div>
                    <label className="inline-flex items-center">
                        <input
                            type="checkbox"
                            onChange={(e) => onChange(() => e.target.checked)}
                            className="
                                    rounded
                                    border-gray-300
                                    text-indigo-600
                                    shadow-sm
                                    focus:border-pas-peach-dark
                                    focus:ring
                                    focus:ring-offset-0
                                    focus:ring-pas-peach-darkest
                                    focus:ring-opacity-50"
                            checked={value} />
                        <span className="ml-2">{label}</span>
                    </label>
                </div>
            </div>
        </div>
    )
}

export const FormColorPicker = ({ label, value, onChange, groupName, options }) => {
    if (!options) return (<></>);
    const colourPalettes = useColourPalettes();
    return (
        <div className="flex flex-col">
            <span className='font-semibold uppercase'>{label}</span>
            <label className="inline-flex">
                <div className="flex flex-wrap">
                    {
                        options.map(
                            (x, i) => {
                                const result = colourPalettes.find(c => c.colour === x.value);
                                if (!result) {
                                    console.log("Unable to find color " + x.value);
                                    return <div key={i}></div>
                                }
                                const style = { "backgroundColor": result.hex };
                                return <label key={i} className="inline-flex items-center pr-2">
                                    <input
                                        value={x.value}
                                        name={groupName}
                                        type="radio"
                                        checked={x.value == value}
                                        onChange={(e) => onChange(() => e.target.value)}
                                        className="
                                        w-8 h-8 
                                        rounded-full inline-block 
                                        mt-2 py-1 mx-2 mb-2                                        
                                        cursor-pointer
                                        border-0
                                        bg-no-repeat bg-center bg-contain  
                                        shadow-md shadow-slate-300 
                                        focus:ring focus:ring-pas-white focus:ring-opacity-0 focus:ring-transparent                                      
                                        checked:w-10 checked:h-10 checked:py-2 checked:mx-1 
                                        checked:shadow-slate-500 checked:shadow-lg"
                                        style={style}
                                    />
                                </label>
                            }
                        )}
                </div>
            </label>
        </div>
    )
}