import * as React from "react";
import pasteleriaHeading from "../images/pasteleria-heading.svg";

export default function BrandHeading({ title }) {
    return (
        <section className="flex flex-col text-center py-8 pb-14">           
            <img src={pasteleriaHeading} className="mx-auto max-h-12" alt="Pasteleria" />
            <h1 className="font-medium text-4xl p-6 pb-0 ">
                / {title}
            </h1>
        </section>
    )
}