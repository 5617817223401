import * as React from "react"
import Seo from "../components/seo"
import BrandHeading from "../components/brand-heading";
import MainCakeImage from "../components/main-cake-image";

export default function About() {
    return (
        <>
            <Seo title="About" />
            <BrandHeading title="about us" />
            <section>
                <div className="text-justify font-sans-serif font-light max-w-3xl mx-auto">
                    <p className="pb-4">Pasteleria is a mum and daughter team who both came from non-hospitality careers.  Marlene has been in the I.T. industry for years and Thea, her daughter, has a degree in Spatial Design.</p>
                    <p className="pb-4">Marlene has always wanted to open up her own café so when Thea took a year off from work and started regularly baking at home, she saw her daughter's potential in baking and encouraged her to take up a short course while she was on break.  Little did she know, Thea would then go on completing a Diploma in Pâtisserie - a course she thoroughly enjoyed and excelled in, and eventually propelled her to switch careers.</p>
                    <p className="pb-4">After graduating from her Pâtisserie course at AUT University, Thea then started working as a Pastry Chef at SkyCity where she honed her skills even further.  Thea has always thought of helping her mum fulfil her dream of having a cafe one day but with both of them being busy with work, it was difficult to plan or start anything.</p>
                    <p className="pb-4">Fast forward to our first COVID Level 4 lockdown and suddenly they’ve got all the time in their hands to start putting ideas together and by the end of lockdown, they drafted up a business plan and went on preparing a bakery that not only produces great cakes and pastries but one that can also cater to the increasing number of people with dietary requirements in New Zealand today. </p>
                </div>
            </section>

        </>
    )
}