import React, { useState, useRef, useEffect } from 'react';
import { navigate } from "gatsby"
import { useCartActionContext } from "./cart-context";
import { FormInputText, FormColorPicker, FormSelect, FormButton, FormRadioGroup } from '../components/form-elements';
import { getMessageTopperPrice } from "../helpers/cake-message-price-helper";
import useUpdateEffect from '../hooks/use-update-effect';
export default function CakeForm({ cake }) {
  const cakeRef = useRef(cake);
  const {
    textures,
    flavours,
    options,
    decorations,
    cakeShapes,
    set1Colours,
    set2Colours,
    set3Colours,
    set4Colours,
    set5Colours,
    set6Colours,
    set7Colours,
    set8Colours,
    set9Colours,
    set10Colours
  } = getCakeProperties(cakeRef.current);
  const { addCake, updateCake } = useCartActionContext();
  const cakeAction = cake.key ? updateCake : addCake;
  const messageTopperProduct = getMessageTopperPrice();
  const getFirstOrDefault = (value, array) => value ? value : (array ? array[0] : null);

  const [price, setPrice] = useState();
  const [selectedPriceId, setCakePriceId] = useState(() => getFirstOrDefault(cake.selectedPriceId, options.map(x => x.id)));
  const [selectedFlavour, setCakeFlavour] = useState(() => getFirstOrDefault(cake.selectedFlavour, flavours));
  const [selectedTexture, setCakeTexture] = useState(() => getFirstOrDefault(cake.selectedTexture, textures));
  const [selectedDecoration, setCakeDecoration] = useState(() => getFirstOrDefault(cake.selectedDecoration, decorations));
  const [selectedCakeShape, setCakeShape] = useState(() => getFirstOrDefault(cake.selectedCakeShape, cakeShapes));
  const [selectedSet1Colour, setCakeSet1Colour] = useState(() => getFirstOrDefault(cake.selectedSet1Colour, set1Colours));
  const [selectedSet2Colour, setCakeSet2Colour] = useState(() => getFirstOrDefault(cake.selectedSet2Colour, set2Colours));
  const [selectedSet3Colour, setCakeSet3Colour] = useState(() => getFirstOrDefault(cake.selectedSet3Colours, set3Colours));
  const [selectedSet4Colour, setCakeSet4Colour] = useState(() => getFirstOrDefault(cake.selectedSet4Colours, set4Colours));
  const [selectedSet5Colour, setCakeSet5Colour] = useState(() => getFirstOrDefault(cake.selectedSet5Colours, set5Colours));
  const [selectedSet6Colour, setCakeSet6Colour] = useState(() => getFirstOrDefault(cake.selectedSet6Colours, set6Colours));
  const [selectedSet7Colour, setCakeSet7Colour] = useState(() => getFirstOrDefault(cake.selectedSet7Colours, set7Colours));
  const [selectedSet8Colour, setCakeSet8Colour] = useState(() => getFirstOrDefault(cake.selectedSet8Colours, set8Colours));
  const [selectedSet9Colour, setCakeSet9Colour] = useState(() => getFirstOrDefault(cake.selectedSet9Colours, set9Colours));
  const [selectedSet10Colour, setCakeSet10Colour] = useState(() => getFirstOrDefault(cake.selectedSet10Colours, set10Colours));

  const [cakeTopperMessage, setCakeTopperMessage] = useState(() => cake.cakeTopperMessage ? cake.cakeTopperMessage : "");
  const [cakeTopperType, setCakeTopperType] = useState(() => cake.cakeTopperType ? cake.cakeTopperType : "");

  const updateCakeTopperMessage = (e) => {
    setCakeTopperMessage(e().substring(0, 50));
  }
  useEffect(() => {
    const item = options.find(x => x.id === selectedPriceId)
    cakeRef.current = {
      ...cakeRef.current
      , selectedPriceId
      , selectedSize: item.size
      , selectedPrice: item.price
      , selectedFlavour
      , selectedTexture
      , selectedDecoration
      , selectedSet1Colour
      , selectedSet2Colour
      , selectedSet3Colour
      , selectedSet4Colour
      , selectedSet5Colour
      , selectedSet6Colour
      , selectedSet7Colour
      , selectedSet8Colour
      , selectedSet9Colour
      , selectedSet10Colour
      , cakeTopperMessage: cakeTopperType ? cakeTopperMessage.trim() : ""
      , cakeTopperType
    }
    setPrice(cakeRef.current.selectedPrice);
  }, [selectedPriceId
    , selectedFlavour
    , selectedTexture
    , selectedDecoration
    , cakeTopperMessage
    , cakeTopperType
    , selectedSet1Colour
    , selectedSet2Colour
    , selectedSet3Colour
    , selectedSet4Colour
    , selectedSet5Colour
    , selectedSet6Colour
    , selectedSet7Colour
    , selectedSet8Colour
    , selectedSet9Colour
    , selectedSet10Colour
  ]);

  return (
    <>
      <div className='flex justify-between items-end'>
        <h3 className="font-bold text-2xl uppercase mb-4">{cake.name}</h3>
        <h3 className="font-semibold text-pas-gray text-3xl mb-4">${price}</h3>
      </div>
      <p className="mb-4 text-justify font-light">
        {cake.description}
      </p>
      <div className="grid grid-cols-1 gap-6 max-w-2xl mx-auto mt-8">
        <FormSelect
          label={"SIZE"}
          value={selectedPriceId} onChange={setCakePriceId}
          options={options.map(x => { return { value: x.id, label: `${x.size}` }; })}
        />
        <FormSelect
          label={"FLAVOUR"}
          value={selectedFlavour} onChange={setCakeFlavour}
          options={flavours?.map(x => { return { value: x, label: x }; })}
        />
        <FormSelect
          label={"TEXTURE"}
          value={selectedTexture} onChange={setCakeTexture}
          options={textures?.map(x => { return { value: x, label: x }; })}
        />
        <FormSelect
          label={"DECORATION"}
          value={selectedDecoration} onChange={setCakeDecoration}
          options={decorations?.map(x => { return { value: x, label: x }; })}
        />
        <FormSelect
          label={"SHAPE"}
          value={selectedCakeShape} onChange={setCakeShape}
          options={cakeShapes?.map(x => { return { value: x, label: x }; })}
        />

        <FormColorPicker
          label={cakeRef.current.set1ColourLabel}
          value={selectedSet1Colour}
          onChange={setCakeSet1Colour}
          groupName={"set1Colours"}
          options={set1Colours?.map(x => { return { value: x, label: x }; })}
        />
        <FormColorPicker
          label={cakeRef.current.set2ColourLabel}
          value={selectedSet2Colour}
          onChange={setCakeSet2Colour}
          groupName={"set2Colours"}
          options={set2Colours?.map(x => { return { value: x, label: x }; })}
        />
        <FormColorPicker
          label={cakeRef.current.set3ColourLabel}
          value={selectedSet3Colour}
          onChange={setCakeSet3Colour}
          groupName={"set3Colours"}
          options={set3Colours?.map(x => { return { value: x, label: x }; })}
        />
        <FormColorPicker
          label={cakeRef.current.set4ColourLabel}
          value={selectedSet4Colour}
          onChange={setCakeSet4Colour}
          groupName={"set4Colours"}
          options={set4Colours?.map(x => { return { value: x, label: x }; })}
        />
        <FormColorPicker
          label={cakeRef.current.set5ColourLabel}
          value={selectedSet5Colour}
          onChange={setCakeSet5Colour}
          groupName={"set5Colours"}
          options={set5Colours?.map(x => { return { value: x, label: x }; })}
        />
        <FormColorPicker
          label={cakeRef.current.set6ColourLabel}
          value={selectedSet6Colour}
          onChange={setCakeSet6Colour}
          groupName={"set6Colours"}
          options={set6Colours?.map(x => { return { value: x, label: x }; })}
        />

        <FormColorPicker
          label={cakeRef.current.set7ColourLabel}
          value={selectedSet7Colour}
          onChange={setCakeSet7Colour}
          groupName={"set7Colours"}
          options={set7Colours?.map(x => { return { value: x, label: x }; })}
        />
        <FormColorPicker
          label={cakeRef.current.set8ColourLabel}
          value={selectedSet8Colour}
          onChange={setCakeSet8Colour}
          groupName={"set8Colours"}
          options={set8Colours?.map(x => { return { value: x, label: x }; })}
        />
        <FormColorPicker
          label={cakeRef.current.set9ColourLabel}
          value={selectedSet9Colour}
          onChange={setCakeSet9Colour}
          groupName={"set9Colours"}
          options={set9Colours?.map(x => { return { value: x, label: x }; })}
        />
        <FormColorPicker
          label={cakeRef.current.set10ColourLabel}
          value={selectedSet10Colour}
          onChange={setCakeSet10Colour}
          groupName={"set10Colours"}
          options={set10Colours?.map(x => { return { value: x, label: x }; })}
        />

        <div>
          <h1 className='font-semibold mb-2'>MESSAGE ON CAKE +${messageTopperProduct.price}</h1>
          <div className="flex justify-between 
          flex-col
          sm:flex-row mb-4">
            <label className='flex items-end mb-2'>
              <FormRadioGroup
                label={""}
                value={cakeTopperType}
                onChange={setCakeTopperType}
                groupName={"cakeTopperType"}
                inline={true}
                options={[
                  { value: "", labels: [] },
                ]}
              /><span className='text-base -ml-4 -mb-1 inline-block align-bottom'>NONE</span></label>
            <label className='flex items-end mb-2'>
              <FormRadioGroup
                label={""}
                value={cakeTopperType}
                onChange={setCakeTopperType}
                groupName={"cakeTopperType"}
                inline={true}
                options={[
                  { value: "whiteRibbon", labels: [] },
                ]}
              /><span className='text-base -ml-4 -mb-1 inline-block align-bottom'>WHITE RIBBON<span className='font-light'>(EDIBLE)</span> </span></label>
            <label className='flex items-center mb-2 '>
              <FormRadioGroup
                label={""}
                value={cakeTopperType}
                onChange={setCakeTopperType}
                groupName={"cakeTopperType"}
                inline={true}
                options={[
                  { value: "2dCakeTopper", labels: [] },
                ]}
              /><span className='text-base -ml-4 -mb-1 inline-block align-bottom'>2D CAKE TOPPER<span className='font-light'>(INEDIBLE)</span></span></label>
          </div>
          {cakeTopperType ? <div>
            <FormInputText label={""} value={cakeTopperMessage} onChange={updateCakeTopperMessage} maxLength={"50"} />
            <span className="text-gray-400 italic font-light font-sans-serif text-xs">Max of 50 characters only ({50 - cakeTopperMessage.length} chars left)</span>

          </div> : <></>}

        </div>

        <FormButton label={cakeRef.current.key ? "UPDATE CART" : "ADD TO CART"} onClick={() => { cakeAction(cakeRef.current); navigate("/cart/") }} />
      </div>
      <GeneralCakeInformation />
    </>
  );
}

const getCakeProperties = (cake) => {
  return {
    textures: cake.textures,
    flavours: cake.flavours,
    options: cake.options,
    decorations: cake.decorations,
    cakeShapes: cake.cakeShapes,
    set1Colours: cake.set1Colours,
    set2Colours: cake.set2Colours,
    set3Colours: cake.set3Colours,
    set4Colours: cake.set4Colours,
    set5Colours: cake.set5Colours,
    set6Colours: cake.set6Colours,
    set7Colours: cake.set7Colours,
    set8Colours: cake.set8Colours,
    set9Colours: cake.set9Colours,
    set10Colours: cake.set10Colours
  };
};

function GeneralCakeInformation() {
  return (
    <div className="py-10">
      <hr className="mb-8" />
      <div className="font-light text-gray-600 text-sm">

        <div className="mb-4">
          <h3 className='font-semibold'>Important note:</h3>
          <p>
            Please use our images only as a guide as each cake is crafted individually and unique on its own. For fresh flowers requests, please note that flowers may vary depending on seasonal changes and availability.
          </p>
        </div>

        <div className="mb-4">
          <h3 className='font-semibold'>Estimated serving size:</h3>
          <div>4-inch cake — 8 dessert / 12 coffee serves.
          </div>
          <div>6-inch cake — 16 dessert / 24 coffee serves.</div>
          <div>8-inch cake — 52 dessert / 88 coffee serves.</div>
        </div>
      </div>

    </div>
  );
}

