import * as React from "react"
import { Link, navigate } from "gatsby"

function Footer() {
	return (
		<footer className="py-2 pt-4 md:pt-10 w-full text-center text-sm text-gray-700">
			<div className="font-semibold flex flex-row 
			text-xs sm:text-sm
			justify-between max-w-lg mx-auto mb-1 sm:mb-2">
				<Link to='/contact'>Contact Us</Link>
				<Link to='/refund-policy'>Refund Policy</Link>
				<Link to='/terms-and-conditions'>Terms & Conditions</Link>
				<Link to='/privacy-policy'>Privacy Policy</Link>				
			</div>
			<div className="mb-4 font-light">© {new Date().getFullYear()} Pasteleria. All Rights Reserved.</div>
		</footer>
	);
}

export default Footer;

