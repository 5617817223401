
export const toTimeFormat = (datetime) => {
    const options = { hour: 'numeric', minute: 'numeric' };
    try {
        return new Intl.DateTimeFormat('en-NZ', options).format(datetime)
    } catch {
        return "";
    }

}


export const toDateFormat = (datetime) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };    
    try {
        return new Intl.DateTimeFormat('en-NZ', options).format(datetime)
    } catch {
        return "";
    }
}