import React, { useState } from "react"
import Seo from "../components/seo"
import { loadStripe } from "@stripe/stripe-js"
import { useCartItemsContext, useCartActionContext } from "../components/cart-context"

const buttonStyles = {
    fontSize: "13px",
    textAlign: "center",
    color: "#000",
    padding: "12px 60px",
    boxShadow: "2px 5px 10px rgba(0,0,0,.1)",
    backgroundColor: "rgb(255, 178, 56)",
    borderRadius: "6px",
    letterSpacing: "1.5px",
}

const buttonDisabledStyles = {
    opacity: "0.5",
    cursor: "not-allowed",
}


let stripePromise
const getStripe = () => {
    if (!stripePromise) {
        stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY)
    }
    return stripePromise
}

function Checkout() {
    const cartItems = useCartItemsContext();
    const lineItems = cartItems.map(x=>{return { price: x.selectedPriceId, quantity: x.quantity }});

    const [loading, setLoading] = useState(false)
    const redirectToCheckout = async event => {
        event.preventDefault()
        setLoading(true)
        const stripe = await getStripe()
        
        const { error } = await stripe.redirectToCheckout({
            mode: "payment",
            lineItems,
            successUrl: `http://localhost:8000/checkout/`,
            cancelUrl: `http://localhost:8000/`,
        })
        if (error) {
            console.error("Error:", error)
            setLoading(false)
        }
    }
    return (
        <>
            <Seo title="Checkout" />

            <button
                disabled={loading}
                style={
                    loading ? { ...buttonStyles, ...buttonDisabledStyles } : buttonStyles
                }
                onClick={redirectToCheckout}
            >
                Purchase
            </button>
        </>
    )
}

export default Checkout
