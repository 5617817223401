import React, { useState } from "react"
import Seo from "../components/seo"
import BrandHeading from "../components/brand-heading";
import MainCakeImage from "../components/main-cake-image";
import {
    FormInputText, FormInputEmail, FormInputTel, FormInputDateTime
    , FormSelect, FormTextArea, FormRadioGroup
    , FormInputAddressAutoComplete, FormButton, FormLinkButton
} from '../components/form-elements';
import Axios from "axios";
import useUpdateEffect from "../hooks/use-update-effect";
import usePickupAddress from "../hooks/use-pickup-address";
import { dateValidation, requiredValidation, emailValidation } from "../helpers/form-validation-helper";

export default function Customise() {

    const [name, setName] = useState(() => "");
    const nameValidations = [(x) => requiredValidation(x, "Name")];

    const [email, setEmail] = useState(() => "");
    const emailValidations = [(x) => requiredValidation(x, "Email"), emailValidation];

    const [phone, setPhone] = useState(() => "");
    const phoneValidations = [(x) => requiredValidation(x, "Phone")];

    const [occasion, setOccasion] = useState(() => "");
    const occasionValidations = [(x) => requiredValidation(x, "Occasion")];

    const [cakeSize, setCakeSize] = useState(() => "6 INCH");
    const [specialRequest, setSpecialRequest] = useState(() => "");

    const [collectionDate, setCollectionDate] = useState(() => "");
    const collectionDateValidations = [(x) => requiredValidation(x, "Date"), dateValidation];

    const [fulfillment, setfulfillment] = useState(() => "pickUp");
    const [address, setAddress] = useState(() => "");
    const addressValidations = [(x) => requiredValidation(x, "Address")];

    const [cakeFlavour, setCakeFlavour] = useState(() => "BANANA CARAMEL");

    const [formSubmitted, setFormSubmitted] = useState(() => false);
    const [formSubmitDisabled, setFormSubmitDisabled] = useState(() => true);
    const [formProcessing, setFormProcessing] = useState(() => false);

    const form = {
        name, setName, nameValidations,
        email, setEmail, emailValidations,
        phone, setPhone, phoneValidations,
        occasion, setOccasion, occasionValidations,
        cakeSize, setCakeSize,
        specialRequest, setSpecialRequest,
        collectionDate, setCollectionDate, collectionDateValidations,
        fulfillment, setfulfillment,
        address, setAddress, addressValidations,
        cakeFlavour, setCakeFlavour,
        setFormSubmitted,
        formSubmitDisabled,
        formProcessing
    }

    const config = {
        baseURL: `${process.env.GATSBY_API_URL}/`,
        headers: {
            'x-api-key': `${process.env.API_KEY}`
            , 'Content-Type': 'application/json'
        },
        timeout: 10000
    }

    const formSubmit = (e) => {
        e.preventDefault();
        setFormProcessing(true);
        const instance = Axios.create(config);
        instance
            .post("/custom-order-request", { ...form })
            .then(result => {
                setFormSubmitted(true);
                setFormProcessing(false);
            })
            .catch(err => {
                console.error(err);
                //TODO: Error Page                
            })
    };

    useUpdateEffect(
        () => {
            if (nameValidations.find(f => f(name))) setFormSubmitDisabled(true);
            else if (emailValidations.find(f => f(email))) setFormSubmitDisabled(true);
            else if (phoneValidations.find(f => f(phone))) setFormSubmitDisabled(true);
            else if (occasionValidations.find(f => f(occasion))) setFormSubmitDisabled(true);
            else if (collectionDateValidations.find(f => f(collectionDate))) setFormSubmitDisabled(true);
            else if (fulfillment != "pickUp" && addressValidations.find(f => f(address))) setFormSubmitDisabled(true);
            else setFormSubmitDisabled(false);

        },
        [name, email, phone, occasion, collectionDate, address, fulfillment]
    );

    return (
        <>
            <Seo title="Customise" />
            <BrandHeading title="customise" />
            <section className="text-justify text-sm sm:text-base md:text-lg">
                {!formSubmitted ?
                    <div className="max-w-3xl mx-auto">
                        <div className="text-center mb-10">
                            <MainCakeImage />
                        </div>
                        <WhenToOrder />
                        <Pricing />
                        <hr className="my-8 md:my-20" />
                        <Form form={form} onSubmit={formSubmit} />
                    </div>
                    :
                    <ThankYou />
                }
            </section>
        </>
    )
}

function Form({ form, onSubmit }) {
    return (
        <div className="grid grid-cols-1 gap-2 md:gap-6 max-w-2xl mx-auto mt-8">
            <div className="italic font-light">* please fill out required fields</div>
            <FormInputText label={"NAME *"}
            value={form.name} onChange={form.setName} validations={form.nameValidations} />
            <FormInputEmail label={"EMAIL *"}  
            value={form.email} onChange={form.setEmail} validations={form.emailValidations} />
            <FormInputTel label={"PHONE *"} 
            value={form.phone} onChange={form.setPhone} validations={form.phoneValidations} />
            <FormInputText label={"OCCASION *"}             
            value={form.occasion} onChange={form.setOccasion} validations={form.occasionValidations} />
            <FormSelect
                label={"CAKE SIZE *"}
                value={form.cakeSize} onChange={form.setCakeSize}
                options={[
                    { value: "6 INCH", label: "6 INCH (32 dessert serves / 56 coffee serves)" },
                    { value: "4 + 6 INCH", label: "4 + 6 INCH (44 dessert serves / 80 coffee serves)" },
                    { value: "8 INCH", label: "8 INCH (52 dessert serves / 96 coffee serves)" },
                    { value: "6 + 8 INCH", label: "6 + 8 INCH (84 dessert serves / 152 coffee serves)" },
                    { value: "4 + 6 + 8 INCH", label: "4 + 6 + 8 INCH (96 dessert serves / 168 coffee serves)" },
                    { value: "6 + 8 + 10 INCH", label: "6 + 8 + 10 INCH (160 dessert serves / 296 coffee serves)" },
                    { value: "4 + 6 + 8 + 10", label: "4 + 6 + 8 + 10 INCH (172 dessert serves / 312 coffee serves)" },
                ]}
            />
            <FormRadioGroup
                label={"CAKE FLAVOUR *"}
                value={form.cakeFlavour}
                onChange={form.setCakeFlavour}
                groupName={"cakeFlavour"}
                options={[
                    { value: "BANANA CARAMEL", labels: ["BANANA CARAMEL", "banana sponge - dulce de leche - banana cream"] },
                    { value: "COFFEE HAZELNUT", labels: ["COFFEE HAZELNUT", "chocolate sponge - hazelnut praline - coffee cream"] },
                    { value: "CHOCOLATE RASPBERRY", labels: ["CHOCOLATE RASPBERRY", "chocolate sponge	- raspberry jam - dark chocolate cream"] },
                    { value: "LEMON", labels: ["LEMON", "lemon sponge	- lemon curd - lemon cream"] },
                    { value: "MATCHA", labels: ["MATCHA", "vanilla sponge - red bean (optional / on request) - matcha cream"] },
                    { value: "STRAWBERRY VANILLA", labels: ["STRAWBERRY VANILLA", "vanilla sponge - strawberry jam - white chocolate cream"] },
                    { value: "TROPICAL", labels: ["TROPICAL", "vanilla sponge - passionfruit jam - mango cream"] },
                    { value: "VANILLA WHITE CHOCOLATE", labels: ["VANILLA WHITE CHOCOLATE", "vanilla sponge - vanilla white chocolate cream"] }
                ]}
            />
            <AllergenWarning />
            <SpecialRequestInfo form={form} />
            
            <div className="flex gap-10">


                <FormRadioGroup
                    label={""}
                    value={form.fulfillment}
                    onChange={form.setfulfillment}
                    groupName={"fulfillment"}
                    inline={true}
                    options={[
                        { value: "pickUp", labels: ["PICK-UP"] },
                    ]}
                />
                <FormRadioGroup
                    label={""}
                    value={form.fulfillment}
                    onChange={form.setfulfillment}
                    groupName={"fulfillment"}
                    inline={true}
                    options={[
                        { value: "delivery", labels: ["DELIVERY"] },
                    ]}
                />
            </div>
            <div className="mb-2"></div>
            <FormInputDateTime label={form.fulfillment == "pickUp" ? "COLLECTION DATE *" : "DELIVERY DATE *"}
                value={form.collectionDate}
                onChange={form.setCollectionDate}
                validations={form.collectionDateValidations}
            />
            {
                (form.fulfillment == "pickUp") ?
                    <label className="block">
                        <span className="block font-semibold mb-2">{"COLLECTION ADDRESS"}</span>
                        <p>{usePickupAddress()}</p>
                    </label>
                    :
                    <FormInputAddressAutoComplete
                        label={"DELIVERY ADDRESS *"}
                        value={form.address}
                        onChange={form.setAddress}                        
                        validations={form.addressValidations} />

            }


            <FormButton
                disabled={form.formSubmitDisabled || form.formProcessing}
                className={"my-4"}
                label={"SUBMIT"}
                onClick={e => { onSubmit(e) }} />
            <p className="text-xs italic text-center text-pas-orange">{form.formSubmitDisabled ? `--- Please fill-out required (*) fields to enable the SUBMIT ---` : ""}</p>
        </div>
    )
}

function AllergenWarning() {
    return (
        <div className="bg-pas-peach text-pas-white text-center py-4 my-4 font-sans-serif text-sm rounded-md">
            <p className="leading-4 px-4">
                <strong>GLUTEN-FREE FRIENDLY OPTIONS AVAILABLE ON REQUEST.</strong>
            </p>
            <p className="leading-4 px-4 pb-4">
                <strong>PLEASE INFORM US BELOW.</strong>
            </p>
            <hr className="py-2" />
            <p className="leading-4 text-justify px-4 font-extralight">
                <strong className="font-bold">ALLERGEN WARNING</strong>
                : We take allergies seriously and have designated equipments & utensils when making our gluten-free friendly cakes. 
                However, while we strive to avoid cross-contamination in preparing our GF friendly cakes, 
                we would like to emphasise that we do handle products containing  
                <span className="font-bold"> gluten, dairy, eggs and soy</span> in our kitchen.
            </p>
        </div>
    )
}

function SpecialRequestInfo(form) {
    return (
        <div>
            <span className="font-semibold mb-2 block">SPECIAL REQUESTS *</span>
            <p className="font-sans-serif  font-thin">
                Do you have a link to your reference photo? A theme or a colour scheme? What kind of design/decorations do you want? Would you like to add a cake topper or an inscription? Feel free to write your requests below. The more information we have, the better!
            </p>
            <FormTextArea label={""} value={form.specialRequest} onChange={form.setSpecialRequest} />
        </div>
    )
}

function WhenToOrder() {
    return (
        <div className="font-sans-serif font-light">
            <p className="mb-6">Do you have a particular design in mind? Please fill out your details below and we will contact you as soon as we can!</p>
            <h3 className="text-md font-semibold leading-10 mb-4">WHEN TO ORDER</h3>
            <p>
                <span className="font-normal">For single and two-tier custom cake orders</span>
                , we require <span className="underline">at least 2 weeks' notice</span> prior to your collection date.</p>
            <p className="mb-4">
                <strong>For taller cake orders (three-tiers or more)</strong>
                , please give us <span className="underline">3-4 weeks' notice</span> prior to your collection date.
            </p>
            <p className="mb-6">Our single-tier cakes are approx. 7 inches high. All our cakes are finished with Italian buttercream &/or chocolate spray.
                <strong> We do not cover cakes with fondant.</strong>
            </p>

        </div>
    )
}

function Pricing() {
    return (
        <>
            <h3 className="text-md font-semibold leading-10">PRICING</h3>
            <p className="mb-6 font-sans-serif font-light">
                Cake prices may vary according to the complexity of design and special requirements.
                <strong>
                    Our starting price for custom-made cakes is $180</strong>
                . Please use our price guide below for an estimate:
            </p>
            <PricingEntry size={"6 INCH"} description={"32 dessert serves / 56 coffee serves from $180."} />
            <PricingEntry size={"4 + 6 INCH"} description={"44 dessert serves / 80 coffee serves from $250."} />
            <PricingEntry size={"8 INCH"} description={"52 dessert serves / 96 coffee serves from $290."} />
            <PricingEntry size={"6 + 8 INCH"} description={"84 dessert serves / 152 coffee serves from $460."} />
            <PricingEntry size={"4 + 6 + 8 INCH"} description={"96 dessert serves / 168 coffee serves from $530."} />
            <PricingEntry size={"6 + 8 + 10 INCH"} description={"160 dessert serves / 296 coffee serves from $890."} />
            <PricingEntry size={"4 + 6 + 8 + 10 INCH"} description={"172 dessert serves / 312 coffee serves from $980."} />
        </>
    )
}

function PricingEntry({ size, description }) {
    return (
        <div className="flex flex-col sm:flex-row mb-4">
            <span className="basis-full sm:basis-4/12 px-1 sm:pr-6 text-center sm:text-right">
                {size}
            </span>
            <span className="basis-full sm:basis-8/12 px-1 sm:pl-6 text-center sm:text-left font-sans-serif font-light">
                {description}
            </span>
        </div>
    )
}


function ThankYou() {
    return (
        <>
            <div className="bg-pas-peach text-pas-white text-center py-4">
                <p className="leading-4 px-4">
                    <strong>THANK YOU FOR YOUR ORDER!</strong>
                </p>
            </div>
            <div className="text-center mb-8">
                <p className="leading-4 my-2 mt-8 text-sm">You should receive a confirmation email regarding your custom cake order shortly.
                </p>
                <p className="leading-4 my-2 text-sm">We will contact you within 48 hours to finalise your cake order.
                </p>
                <p className="leading-4 my-2 text-sm">Please check your spam / junk folder if you haven't received any email from us within this timeframe.
                </p>
                <p className="leading-4 my-6 text-sm">In the meantime, check out the rest of our site for more delightful things we have in store for you!
                </p>
            </div>
            <div className="flex flex-col max-w-xs mx-auto">
                <FormLinkButton label={"CONTINUE SHOPPING"} to={"/cakes"} className={"mb-4"} />
            </div>
        </>
    )
}
