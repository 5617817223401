import React, { useEffect } from "react"
import Seo from "../components/seo"
import BrandHeading from "../components/brand-heading";
import { FormLinkButton } from '../components/form-elements';
import { useCartActionContext } from "../components/cart-context";
import { useCheckoutActionContext } from "../components/checkout-context";

export default function CheckoutSuccess() {
    const cartStore = useCartActionContext();
    const checkoutStore = useCheckoutActionContext();
    
    useEffect(
        () => {
            cartStore.clearAll();
            checkoutStore.clearAll();            
        }
        , []);

    return (
        <>
            <Seo title="Checkout Success" />
            <BrandHeading title="Checkout" />
            <section className="text-center">
                <hr className="my-8" />
                <h1 className="mb-8 text-lg">THANK YOU FOR YOUR ORDER!</h1>
                <p className="mb-4">You should receive a confirmation email regarding your cake order shortly.</p>
                <p className="mb-4">Please check your spam / junk folder if you haven’t received any email from us within this timeframe.</p>
                <p className="mb-4">In the meantime, check out the rest of our site for more delightful things we have in store for you!</p>
                <hr className="mt-8 mb-12" />
                <div className="flex flex-col max-w-xs mx-auto">
                    <FormLinkButton label={"OUR PRODUCTS"} to={"/cakes"} className={"mb-4"} />
                    <FormLinkButton label={"WHO WE ARE"} to={"/about"} className={"mb-4"} />
                    <FormLinkButton label={"HOME"} to={"/"} className={"mb-4"} />
                </div>
            </section>
        </>
    )
}